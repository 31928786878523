import store from '../../../core/store';
import {
  CLEAR_ALL_CATEGORIES_MUTATION,
  NAMESPACE as USER_SETTINGS_NAMESPACE,
  REMOVE_CATEGORY_MUTATION,
  SET_CATEGORY,
  SET_IN_CATEGORY,
} from '../store/sessionSettings';
import { ISessionSettingsService } from './types';

/**
 * Manages session settings.
 * All methods are scoped to the current user's session
 */
export class SessionSettingsService implements ISessionSettingsService {
  /**
   * Gets category state for the given name
   *
   * @param categoryName - name of the category to retrieve
   */
  getCategory(categoryName: string): unknown {
    const userName = store.getters.user.userName;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${USER_SETTINGS_NAMESPACE}/getCategory`](userName, categoryName);
  }

  /**
   * Saves category state under the given name
   *
   * @param categoryName - name to save the category under
   * @param categoryState - state to save
   */
  setCategory(categoryName: string, categoryState: Record<any, any>): void {
    const userName = store.getters.user.userName;
    void store.dispatch(`${USER_SETTINGS_NAMESPACE}/${SET_CATEGORY}`, {
      userName,
      categoryName,
      categoryState,
    });
  }

  /**
   * Gets category state for the given name
   *
   * @param categoryName - name of the category to retrieve
   * @param key - name of the key to retrieve
   */
  getInCategory(categoryName: string, key: string): unknown {
    const userName = store.getters.user.userName;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${USER_SETTINGS_NAMESPACE}/getInCategory`](userName, categoryName, key);
  }

  /**
   * Saves a value under the given key, for the given category
   *
   * @param categoryName - the name of the category
   * @param key - the key in the category to save under
   * @param value - the value to save
   */
  setInCategory(categoryName: string, key: string, value: Record<any, any>): void {
    const userName = store.getters.user.userName;
    void store.dispatch(`${USER_SETTINGS_NAMESPACE}/${SET_IN_CATEGORY}`, {
      userName,
      categoryName,
      key,
      value,
    });
  }

  /**
   * Removes a category
   *
   * @param categoryName - the name of the category to remove
   */
  removeCategory(categoryName: string): void {
    const userName = store.getters.user.userName;
    store.commit(`${USER_SETTINGS_NAMESPACE}/${REMOVE_CATEGORY_MUTATION}`, {
      userName,
      categoryName,
    });
  }

  /**
   * Clears all categories
   */
  clearAllCategories(): void {
    const userName = store.getters.user.userName;
    store.commit(`${USER_SETTINGS_NAMESPACE}/${CLEAR_ALL_CATEGORIES_MUTATION}`, { userName });
  }
}
