<template>
  <div v-if="isVisible" class="pui-attribute-detail-panel">
    <label class="attribute-detail-label">{{ piivoTranslate(detail) }}</label>
    <component
      :is="renderer"
      class="attribute-detail-value"
      :value="value"
      :formatter="formatter"
    ></component>
  </div>
</template>
<script>
import Vue from 'vue';

export default {
  name: 'PuiAttributeDetailPanel',
  props: {
    /**
     * Attribute detail value.
     */
    value: {
      required: true,
    },
    /**
     * Detail Object (from details configuration).
     */
    detail: {
      required: true,
    },
    /**
     * Retrieves the component to render
     */
    getRenderer: {
      type: Function,
      required: true,
    },
  },
  computed: {
    /**
     * Gets current detail renderer (String renderer by default).
     */
    renderer() {
      return this.getRenderer(
        this.detail.options != null && this.detail.options.renderer != null
          ? this.detail.options.renderer
          : 'String'
      );
    },
    /**
     * Gets current detail formatter Object (value: Function and pattern: String).
     */
    formatter() {
      return this.detail.options != null ? this.detail.options.formatter : null;
    },
    /**
     * Is detail panel visible (boolean).
     */
    isVisible() {
      return !this.detail.options || this.detail.options.visible !== false;
    },
    /**
     * Is detail panel disabled (boolean).
     * Not used for the moment (planned for detail edition)
     */
    isDisabled() {
      return this.detail.options && this.detail.options.enabled === false;
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
  },
};
</script>
