import { getFormattingManager } from 'piivo-poster-engine/src/lib/services/formattingManager';

// Details helper
import services from '../../../core/services';

/**
 * Compute details definition (manage formatters and permissions).
 * @param {Object} detailsDefinition : Details definition
 */
export const computeDetailsDefinition = (detailsDefinition) => {
  // Compute details definition
  if (detailsDefinition != null) {
    for (const detail of detailsDefinition) {
      if (detail.options != null) {
        // Get formatter fonctions for formatter names
        if (typeof detail.options.formatter === 'string') {
          detail.options.formatter = {
            value: getFormattingManager().getFormatter(detail.options.formatter),
          };
        } else if (detail.options.formatter && detail.options.formatter.alias != null) {
          detail.options.formatter.value = getFormattingManager().getFormatter(
            detail.options.formatter.alias
          );
        }

        // Manage detail visibility permission
        let visibility = true;
        if (detail.options.visiblePermission != null) {
          if (
            detail.options.visiblePermission.length > 1 &&
            detail.options.visiblePermission[0] === '!'
          ) {
            // Test inversion (!permission_name)
            visibility = !services
              .getService('auth')
              .hasPermission(detail.options.visiblePermission.substring(1));
          } else {
            visibility = services
              .getService('auth')
              .hasPermission(detail.options.visiblePermission);
          }
        }

        detail.options.visible = visibility;

        // Manage detail enabled permission
        let enabled = true;
        if (detail.options.enabledPermission != null) {
          if (
            detail.options.enabledPermission.length > 1 &&
            detail.options.enabledPermission[0] === '!'
          ) {
            // Test inversion (!permission_name)
            enabled = !services
              .getService('auth')
              .hasPermission(detail.options.enabledPermission.substring(1));
          } else {
            enabled = services.getService('auth').hasPermission(detail.options.enabledPermission);
          }
        }

        detail.options.enabled = enabled;
      }
    }
  }
};

export default { computeDetailsDefinition };
