import Vue from 'vue';

import services from '../../../core/services';
import { I18nManager } from '../../../core/services/i18nManager';
import { ILanguagesManagerService } from '../../../core/services/types';
import { Label } from '../../../core/types/i18n';
import { ensureArray } from '../../../utils/array';
import { AttributeTypes } from '../../common/constants';
import { getDamService } from '../services';
import { IFilesService, SearchOptions } from '../services/types';
import { AttributeFilter } from '../types/attributes';

interface AttributeLink {
  /**
   * The URL on which the definition of the link is
   */
  href: string;
}

export interface FormAttribute {
  /**
   * The type of attribute
   */
  type: string;
  fieldName: string;
  /**
   * Links of the attribute
   */
  links: AttributeLink[];
  /**
   * Parameters used by AttributePanel
   */
  parameters: any;
  /**
   * Options used by AttributePanel
   */
  options: any;
  // Added by frontend. If the model ever changes to support
  // static options, this should be removed and rethought
  _staticOptions: {
    attribute: string;
    values: any[];
    label: Label;
    createdDate?: any;
    updatesDate?: any;
    paths?: any;
    itemId: string;
    alias: string;
    // Added by app
    _labelKey: string;
  }[];
}

interface FormTab {
  /**
   * Array of attributes for this tab
   */
  attributes: FormAttribute[];
}

interface FormDefinition {
  /**
   * Array of attribute tabs
   */
  tabs: FormTab[];
}

/**
 * Retrieves all the attribute aliases of the form
 *
 * @param {Object} form - form of tabs of attributes
 * @returns {string} all attribute aliases joined by ','
 */
function generateAttributesString(form: FormDefinition): string {
  if (!form || !form.tabs) {
    return '';
  }
  const attributes: string[] = [];
  form.tabs.forEach((tab) => {
    tab.attributes.forEach((attribute) => {
      attributes.push(attribute.fieldName);
    });
  });

  return [...new Set(attributes)].join(',');
}

/**
 * Get form definition and value for multiple files
 *
 * @param requestNamespace - the namespace to execute api requests under. Used to cancel requests
 * @param {Array} items - files array
 * @param {Function} cb - success callback
 * @param {Function} errorCb - error callback
 * @returns {Promise<void>}
 */
export async function getFormAndValuesByItemsIds(
  requestNamespace: string,
  items: { itemId: string }[]
) {
  const itemIds = items.map((i) => i.itemId);

  // Get common attributes
  const formResponse = await getDamService<IFilesService>('filesService')?.getFilesForm(
    requestNamespace,
    itemIds.join(',')
  );

  const searchConfig: SearchOptions = {
    top: items.length,
    skip: 0,
    filter: itemIds.map((i) => `ItemId:${i}`).join(' OR '),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    attributes: generateAttributesString(formResponse.body),
  };

  // Make search with params to get all files with their values
  const searchResponse = await getDamService<IFilesService>('filesService')?.search(
    requestNamespace,
    searchConfig
  );

  return {
    form: formResponse.body,
    files: searchResponse.body.items,
  };
}

/**
 * Retrieves the first link's data and sets it as 'parameters.links' on
 * the attribute
 *
 * @param {object} attribute - the attribute whose links options to retrieve
 * @returns {Promise<object[]>} select options for the link attribute
 */
export async function getAttributeLinkOptions(attribute: FormAttribute) {
  if (attribute._staticOptions) {
    return attribute._staticOptions;
  } else if (Array.isArray(attribute.links) && attribute.links.length && attribute.links[0].href) {
    const linkRes = await Vue.http.get(attribute.links[0].href);
    const options = (linkRes as any).body.items;

    // AttributePanel needs the links to be on 'parameters.link'
    // So add them manually
    Vue.set(attribute, 'parameters', attribute.parameters || {});
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Vue.set(attribute.parameters, 'links', options);

    Vue.set(attribute, 'options', attribute.options || {});

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return options;
  }

  return [];
}

/**
 * Check if 2 attributes values are equals:
 * - Boolean => both falsy, or both truthy
 * - String => both falsy, or same value
 * - Links => equal length & contain same items by `itemId` property
 * - *Default* => same value
 *
 * @param {Object} valueA - first compare values
 * @param {Object} valueB - second compare values
 * @param {String} type - attribute type
 * @returns {Boolean}
 */
export function attributeValuesAreEqual(valueA: unknown, valueB: unknown, type: AttributeTypes) {
  switch (type) {
    case AttributeTypes.STRING || AttributeTypes.DATE:
      if (!valueA && !valueB) {
        return true;
      }
      return valueA === valueB;
    case AttributeTypes.BOOLEAN:
      return (!valueA && !valueB) || valueA === valueB;
    case AttributeTypes.LINKS: {
      // Wrap values into arrays if needed
      const wrappedValueA = ensureArray(valueA);
      const wrappedValueB = ensureArray(valueB);

      if (wrappedValueA.length !== wrappedValueB.length) {
        return false;
      }

      // If the lengths are the same, the arrays are the same if every item in A is found in B
      return (wrappedValueA as { itemId: string }[]).every(
        (insideValueA) =>
          !!(wrappedValueB as { itemId: string }[]).find(
            (insideValueB) => insideValueA.itemId === insideValueB.itemId
          )
      );
    }
    default:
      return valueA === valueB;
  }
}

/**
 *
 * @param forms - the search forms that the attribute should be in
 * @param attributeName - the input attribute name
 * @returns the corresponding form attribute if found
 */
export const getCorrespondingAttributeFilter = (
  forms: { tabs: { _attributeFilters: AttributeFilter[] }[] }[],
  attributeName?: string
): AttributeFilter | null => {
  const normalizedName = attributeName?.toLocaleLowerCase() || '';

  for (const form of forms) {
    for (const tab of form.tabs) {
      for (const attributeFilter of tab._attributeFilters) {
        const attrLabel = Vue.filter('piivoTranslate')(attributeFilter.attribute) as
          | string
          | undefined;

        if (
          attrLabel
            ?.toLocaleLowerCase()
            .localeCompare(
              normalizedName,
              I18nManager.getI18nLocale(
                services.getService<ILanguagesManagerService>('languages')!.getSelectedLocale()
              ),
              { sensitivity: 'base' }
            ) === 0
        ) {
          return attributeFilter;
        }
      }
    }
  }

  return null;
};

/**
 * @param forms - the search forms that the attribute should be in
 * @param attributeName - the input attribute name
 * @returns if the attribute names corresponds to an attribute label in the given forms
 */
export const checkAttributeFilterExists = (
  forms: { tabs: { _attributeFilters: AttributeFilter[] }[] }[],
  attributeName?: string
): boolean => {
  return !!getCorrespondingAttributeFilter(forms, attributeName);
};
