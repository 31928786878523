<template>
  <pui-dialog
    ref="dialog"
    :showCloseButton="false"
    transition="slide-bottom"
    width="100%"
    class="big-preview big-preview__root"
    @close="onRequestClose"
  >
    <template v-if="zoomedItem" #title>
      <pui-button-list class="big-preview__title-button-list">
        <pui-button
          :disabled="!canDecrementZoomedItemIndex"
          picto="mdi-chevron-left"
          class="btn-only-picto"
          variant="secondary"
          flat
          @click="onRequestPreviousZoomedItemHook"
        />
        <pui-button
          :disabled="!canIncrementZoomedItemIndex"
          picto="mdi-chevron-right"
          class="btn-only-picto"
          variant="secondary"
          flat
          @click="onRequestNextZoomedItemHook"
        />
      </pui-button-list>

      <slot name="picto">
        <i :class="titlePicto"> </i>
      </slot>

      <div class="big-preview__title">
        <slot v-if="zoomedItem" :item="zoomedItem" name="name"></slot>
      </div>

      <pui-flex class="big-preview__title-buttons">
        <pui-button
          :disabled="disableAttributeButtons"
          class="big-preview__title-button big-preview__button-cancel"
          variant="secondary"
          outline
          @click="abandonChanges"
        >
          {{ $t('common.big_preview.cancel') }}
        </pui-button>

        <pui-button
          :disabled="disableAttributeButtons"
          class="big-preview__title-button big-preview__button-save"
          variant="secondary"
          outline
          @click="executeConfirmableBreakingSave()"
        >
          <span class="save-text"> {{ $t('common.big_preview.save') }} </span>

          <template v-if="importInProgress">
            <span class="spacer"></span>
            <svg viewBox="0 0 24 24" class="wait-save-icon">
              <path
                fill="currentColor"
                d="M6,2V8H6V8L10,12L6,16V16H6V22H18V16H18V16L14,12L18,8V8H18V2H6M16,16.5V20H8V16.5L12,12.5L16,16.5M12,11.5L8,7.5V4H16V7.5L12,11.5Z"
              />
            </svg>
          </template>
        </pui-button>

        <pui-button
          class="big-preview__title-button big-preview__button-close"
          variant="secondary"
          outline
          @click="onRequestClose"
        >
          {{ $t('common.big_preview.close') }}
        </pui-button>
      </pui-flex>
    </template>

    <template v-if="zoomedItem" #default>
      <pui-flex direction="row" class="big-preview big-preview__content" flex="1">
        <slot name="body"></slot>

        <pui-flex class="big-preview__thumbnail-container justify-content-center" flex="1">
          <slot v-if="zoomedItem" :item="zoomedItem" name="viewer"></slot>
        </pui-flex>

        <pui-flex class="big-preview__panel-selection-container">
          <pui-side-panel
            v-if="zoomedItem"
            :open="attrsExpansion"
            openWidth="1000px"
            closedWidth="400px"
            mode="width"
            hideButton
            class="big-preview__panel-selection-side-panel"
          >
            <pui-flex class="form-panel-control" alignItems="center">
              <pui-button
                class="toggle-open-button"
                picto="mdi-arrow-top-right-bottom-left"
                flat
                variant="secondary"
                @click="attrsExpansion = !attrsExpansion"
              />
            </pui-flex>
            <div class="meta"><slot :item="zoomedItem" name="meta"> </slot></div>
          </pui-side-panel>
        </pui-flex>
      </pui-flex>
    </template>
  </pui-dialog>
</template>

<script>
export default {
  name: 'PuiBigPreview',
  props: {
    getItemId: {
      type: Function,
      default: (item) => item.itemId,
    },
    getTitlePicto: {
      type: Function,
      default: (item) => '',
    },
    // ACTION CALLBACKS
    /**
     * Callback executed before any breaking changes: closing dialog, changing item.
     *
     * @param {Function} actionCallback - callback to execute after the user indicates to discard
     * the unsaved changes, or after the changes are saved, or if there is nothing to save at all
     *
     * @type {(actionCallback: Function) => void}
     */
    executeConfirmableAction: {
      type: Function,
      required: true,
    },
    /**
     * Callback to trigger the save.
     *
     * @param {Function} actionCallback - callback to execute when the user confirms or abandons the save.
     * The callback should resolve once the user decided to confirm/abandon the changes
     * and the callback is executed.
     *
     * @type {(actionCallback?: Function) => void | Promise<void>}
     */
    executeConfirmableBreakingSave: {
      type: Function,
      required: true,
    },
    /**
     * Callback to reset any changes
     */
    abandonChanges: {
      type: Function,
      required: true,
    },
    // STATE
    /**
     * If any values are dirty. Will enable the save/cancel buttons and
     * prevent exiting the tab
     */
    valuesAreDirty: {
      type: Boolean,
      required: true,
    },
    /**
     * If a file import is in progress. Will disable the save button.
     */
    importInProgress: {
      type: Boolean,
      required: true,
    },
    /**
     * If a save is in progress. Will show a spinner
     */
    saveInProgress: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      zoomedItemIndex: -1,
      attrsExpansion: false,
    };
  },
  computed: {
    /**
     * @returns {object} the zoomed item
     */
    zoomedItem() {
      return this.items[this.zoomedItemIndex] || null;
    },
    /**
     * @returns {boolean} if there is a item after the currently
     * zoomed item
     */
    canIncrementZoomedItemIndex() {
      return this.zoomedItemIndex >= 0 && this.zoomedItemIndex < this.items.length - 1;
    },
    /**
     * @returns {boolean} if there is a item before the currently
     * zoomed item
     */
    canDecrementZoomedItemIndex() {
      return this.zoomedItemIndex >= 1 && this.zoomedItemIndex <= this.items.length - 1;
    },
    /**
     * @returns {string} the class for the title picto
     */
    titlePicto() {
      const titlePicto = this.getTitlePicto(this.zoomedItem);
      return {
        'big-preview__title-picto': true,
        mdi: true,
        [titlePicto]: !!titlePicto,
      };
    },
    /**
     * @returns {boolean} if should disable attribute cancel/save buttons
     */
    disableAttributeButtons() {
      return !this.valuesAreDirty || this.saveInProgress;
    },
  },
  watch: {
    /**
     * Watch the zoomed item to emit whenever it changed
     *
     * @param {object} nextValue - the next value
     * @param {object} prevValue - the previous value
     * @returns {void}
     */
    zoomedItem(nextValue, prevValue) {
      const isSameItem =
        nextValue && prevValue && this.getItemId(nextValue) === this.getItemId(prevValue);

      if (!isSameItem) {
        this.$emit('changeZoomedItem', nextValue);
      }
    },
  },
  methods: {
    // DIALOG
    /**
     * Initializes our state and opens the dialog
     *
     * @param {object[]} items - the items that can be zoomed
     * @param {number} itemIndex - the index of the item that should be zoomed
     */
    open(items, itemIndex) {
      this.items = items;
      this.zoomedItemIndex = itemIndex;

      this.$nextTick(() => {
        this.$refs.dialog.open();
      });
    },
    /**
     * Resets our state and closes the dialog
     */
    close() {
      // Reset state
      this.items = [];
      this.zoomedItemIndex = -1;

      this.$refs.dialog.close();
      this.$emit('closed');
    },
    /**
     * Verifies that the parent won't block closing the dialog (ex: unsaved changes)
     *
     * @returns {void}
     */
    onRequestClose() {
      this.executeConfirmableAction(this.close);
    },
    // ITEMS
    /**
     * Hook to verify that there is a next item, and that there
     * is nothing in the parent component that would block the change.
     * If everything checks out, increments the zoomed item index
     *
     * @returns {void}
     */
    onRequestNextZoomedItemHook() {
      if (this.canIncrementZoomedItemIndex) {
        this.executeConfirmableAction(this.incrementZoomedItemIndex);
      }
    },
    /**
     * Increments zoomedItemIndex
     */
    incrementZoomedItemIndex() {
      this.zoomedItemIndex++;
    },
    /**
     * Hook to verify that there is a previous item, and that there
     * is nothing in the attributes form that would block the change.
     * If everything checks out, decrements the zoomed item index
     */
    onRequestPreviousZoomedItemHook() {
      if (this.canDecrementZoomedItemIndex) {
        this.executeConfirmableAction(this.decrementZoomedItemIndex);
      }
    },
    /**
     * Decrements zoomedItemIndex
     */
    decrementZoomedItemIndex() {
      this.zoomedItemIndex--;
    },
    // OTHER
    /**
     * Display alert when browser or tab are killed
     * @param {Object} e - event
     * @returns {string}
     */
    eventUnloadFunction(e) {
      if (this.valuesAreDirty) {
        e = e || window.event;
        if (e) {
          e.returnValue = '';
        }
        return '';
      }
    },
  },
  /**
   * Component mounted hook
   */
  mounted() {
    // Register listener to display alert on browser or tab closing
    window.addEventListener('beforeunload', this.eventUnloadFunction);
  },
  /**
   * Component beforeDestroy hook
   */
  beforeDestroy() {
    // Unregister listener on destroy
    window.removeEventListener('beforeunload', this.eventUnloadFunction);
  },
};
</script>
