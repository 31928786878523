<template>
  <div class="file-thumbnail-viewer file-thumbnail-viewer__root">
    <template v-if="src">
      <pui-image-viewer
        :src="src"
        :classError="imgErrorClass"
        :classSuccess="imgSuccessClass"
        :classPlaceholder="imgPlaceholderClass"
        :mode="Mode.SRC"
        :lazy="lazy"
        :delayDownloadLength="delayDownloadLength"
        :preventDownload="preventDownload"
        class="file-thumbnail-viewer__image-viewer"
      >
        <!-- Pass all slots -->
        <template v-for="(_, slotIndex) of $scopedSlots" #[slotIndex]="slotProps">
          <slot :name="slotIndex" v-bind="slotProps" />
        </template>

        <template #placeholder>
          <pui-flex
            class="file-thumbail-loader-container"
            justifyContent="center"
            alignItems="center"
          >
            <div class="file-thumbail-loader" />
          </pui-flex>
        </template>

        <template #error>
          <pui-corrupted-thumbnail :class="imgPlaceholderClass" :extension="fallbackText" />
        </template>
      </pui-image-viewer>
    </template>

    <template v-else>
      <pui-file-thumbnail
        :class="imgPlaceholderClass"
        :extension="fallbackText"
        class="file-thumbnail-viewer__no-image-thumbnail"
      />
    </template>
  </div>
</template>

<script>
import { Mode } from 'piivo-ui/src/components/image/ImageViewer';

export const ObjectFit = {
  COVER: 'cover',
  CONTAIN: 'contain',
};

export default {
  name: 'PuiFileThumbnailViewer',
  props: {
    /**
     * Object fit mode for the real image
     *
     * @type {String}
     * @default "cover"
     */
    objectFit: {
      type: String,
      default: ObjectFit.CONTAIN,
      validator: (value) => Object.values(ObjectFit).includes(value),
    },
    /**
     * If the image should only be loaded when visible in the viewport
     *
     * @type {Boolean}
     * @default false
     */
    lazy: {
      type: Boolean,
      default: false,
    },
    /**
     * How long to delay the image download.
     * For lazy mode
     */
    delayDownloadLength: {
      type: Number,
      default: 0,
    },
    /**
     * Extra class for the image
     *
     * @type {String}
     * @default '
     */
    classImg: {
      type: String,
      default: '',
    },
    /**
     * If should prevent downloading the image
     */
    preventDownload: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: null,
    },
    fallbackText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      Mode,
    };
  },
  computed: {
    /**
     * @returns {object} class object for the images
     */
    imgClass() {
      return {
        'file-thumbnail-viewer__img': true,
        [this.objectFit]: !!this.objectFit,
        [this.classImg]: this.classImg,
      };
    },
    /**
     * @returns {object} class object for the error image
     */
    imgErrorClass() {
      return {
        ...this.imgClass,
        error: true,
      };
    },
    /**
     * @returns {object} class object for the placeholder elemnt
     */
    imgPlaceholderClass() {
      return {
        ...this.imgClass,
        placeholder: true,
      };
    },
    /**
     * @returns {object} class object for the actual image
     */
    imgSuccessClass() {
      return {
        ...this.imgClass,
        success: true,
      };
    },
  },
};
</script>
