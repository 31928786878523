<template>
  <pui-dialog
    ref="dialog"
    :showCloseButton="false"
    transition="slide-up"
    class="update-attributes-dialog"
    noAutoClose
  >
    <template #title>
      <i18n path="dam.files.update.warn_unsaved_dialog.title.main">
        <template #primary>
          <span class="update-attributes-dialog__title--bold">
            {{ $t('dam.files.update.warn_unsaved_dialog.title.primary') }}
          </span>
        </template>
        <template #secondary>
          <span class="update-attributes-dialog__subtitle">
            {{ $t('dam.files.update.warn_unsaved_dialog.title.secondary') }}
          </span>
        </template>
      </i18n>
    </template>
    <template #default>
      <pui-flex direction="column" class="update-attributes-dialog-content">
        <span class="update-attributes-dialog-content__message">
          {{ $t('dam.files.update.warn_unsaved_dialog.content') }}
        </span>
        <pui-flex
          v-if="importInProgress"
          class="update-attributes-dialog-content__message-import-in-progress"
        >
          <i class="mdi mdi-alert alert-icon"></i>
          <pui-flex direction="column">
            <i18n
              path="dam.files.update.warn_unsaved_dialog.import_in_progress.primary"
              tag="div"
            ></i18n>
            <i18n
              path="dam.files.update.warn_unsaved_dialog.import_in_progress.secondary"
              tag="div"
            ></i18n>
          </pui-flex>
        </pui-flex>
      </pui-flex>
      <pui-flex direction="row" class="update-attributes-dialog-buttons">
        <pui-button class="cancel-button" outline @click="onCancel">
          {{ $t('dam.files.update.warn_unsaved_dialog.button.cancel') }}
        </pui-button>
        <pui-button class="update-button" outline @click="onCancelUpdate">
          {{ $t('dam.files.update.warn_unsaved_dialog.button.cancel_update') }}
        </pui-button>
        <pui-button
          :disabled="importInProgress"
          class="save-button"
          variant="primary"
          @click="onSaveUpdate"
        >
          <span class="save-text">{{
            $t('dam.files.update.warn_unsaved_dialog.button.save')
          }}</span>
          <!-- This svg corresponds to mdi-timer-sand-empty -->
          <!-- We need to use the svg instead of the font because we want -->
          <!-- to rotate it around its center (font messes up the center) -->
          <svg
            v-if="importInProgress"
            style="width: 24px; height: 24px"
            viewBox="0 0 24 24"
            class="wait-save-icon"
          >
            <path
              fill="currentColor"
              d="M6,2V8H6V8L10,12L6,16V16H6V22H18V16H18V16L14,12L18,8V8H18V2H6M16,16.5V20H8V16.5L12,12.5L16,16.5M12,11.5L8,7.5V4H16V7.5L12,11.5Z"
            />
          </svg>
        </pui-button>
      </pui-flex>
    </template>
  </pui-dialog>
</template>

<script>
export default {
  name: 'PuiUnsavedAttributesDialog',
  props: {
    /**
     * If an import is an progress. Shows a warning
     * and blocks the save button while true
     */
    importInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onCancel: () => {},
      onCancelUpdate: () => {},
      onSaveUpdate: () => {},
    };
  },
  methods: {
    /**
     * Close dialog
     * @returns {void}
     */
    close() {
      this.$refs.dialog.close();
      this.onCancel = () => {};
      this.onCancelUpdate = () => {};
      this.onSaveUpdate = () => {};
    },
    /**
     * Open dialog
     * @param {object} callbacks - object of user callbacks
     * @param {Function} callbacks.onCancel - called when the user cancels his action
     * @param {Function} callbacks.onCancelUpdate - called when the user wants
     * to continue his action and cancel changes
     * @param {Function} callbacks.onSaveUpdate - called when the user wants
     * to continue his action and save changes
     * @returns {void}
     */
    open({ onCancel, onCancelUpdate, onSaveUpdate } = {}) {
      this.onCancel = onCancel;
      this.onCancelUpdate = onCancelUpdate;
      this.onSaveUpdate = onSaveUpdate;

      this.$refs.dialog.open();
    },
  },
};
</script>
