import actionsApi, { ActionDetails, ActionDetailsBase, ActionStatus } from '../api/actions';
import { ActionCallbackManager, IActionsService, PollOptions } from './types';

/**
 * Maps the possible action statuses to the callbacks
 */
const StatusToCallbackMap: { [x: string]: string } = {
  [ActionStatus.NEW]: 'onStatusNew',
  [ActionStatus.ENQUEUED]: 'onStatusEnqueued',
  [ActionStatus.STARTED]: 'onStatusStarted',
  [ActionStatus.FINISHED]: 'onStatusFinished',
  [ActionStatus.INERROR]: 'onStatusInError',
};

/**
 * Status for which to consider the action done, and thus end polling
 */
const EndPollBulkStatuses = [ActionStatus.FINISHED, ActionStatus.INERROR];

/**
 * Default options for polling
 */
export const DefaultPollOptions: PollOptions = {
  nextPollTimeout: 5 * 1000,
  getAction: async (actionId: string) => actionsApi.getAction(actionId),
};

/**
 * Service for actions
 */
export class ActionsService implements IActionsService {
  /**
   * @inheritdoc
   */
  createAction<TResult = unknown>(): Promise<ActionDetails<TResult>> {
    return actionsApi.createAction<TResult>();
  }

  /**
   * @inheritdoc
   */
  runAction<TResult = unknown>(
    actionId: string | number,
    taskName: string,
    parameters: Record<string, object>
  ): Promise<ActionDetails<TResult>> {
    return actionsApi.runAction(actionId, taskName, parameters);
  }

  /**
   * Checks the status of a action.
   * If Finished or InError, will stop polling.
   * Otherwise will just set a timeout for another check
   *
   * @param actionId - the action to check
   * @param callbackManager - object of callback functions
   * @param pollOptions - options for the polling
   */
  async pollAction<Details extends ActionDetailsBase, Err = Error>(
    actionId: string,
    callbackManager: ActionCallbackManager<Details, Err>,
    pollOptions?: Partial<PollOptions<Details>>
  ): Promise<void> {
    const mergedOptions = { ...DefaultPollOptions, ...(pollOptions ?? {}) } as PollOptions<Details>;

    try {
      const res = await mergedOptions.getAction(actionId);

      const bulkDetails = res.body;
      const { status } = bulkDetails;

      // Execute the callback associated to the status
      const callback = (callbackManager as any)[StatusToCallbackMap[status]] as (
        arg: unknown
      ) => Promise<void> | void;
      if (callback) {
        // Invoke the callback, using the manager as 'this'
        void callback.call(callbackManager, bulkDetails);
      }

      // If the action is 'ended', stop polling
      if (EndPollBulkStatuses.includes(status)) {
        return;
      }
    } catch (err) {
      if (callbackManager.onPollError) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        callbackManager.onPollError(err as any);
        // Continue execution
      }
    }

    // If the status did not correspond to an end state, continue polling
    setTimeout(() => {
      void this.pollAction(actionId, callbackManager, mergedOptions);
    }, mergedOptions.nextPollTimeout);
  }
}
