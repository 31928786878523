var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pui-common-drop-zone',{staticClass:"pui-attribute-external-link pui-attribute-external-link__root",attrs:{"dragOverCb":_vm.onDragOver,"dragEnterCb":_vm.onDragEnter,"loading":_vm.isProcessingDrop,"disabled":_vm.disabled},on:{"change":_vm.onDropFiles},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('pui-flex',{staticClass:"pui-attribute-external-link pui-attribute-external-link__content",attrs:{"direction":"column"}},[(!_vm.hideTopMenu)?_c('pui-flex',{staticClass:"pui-attribute-external-link__top"},[(_vm.showLabel)?_c('pui-flex',{staticClass:"pui-attribute-external-link__label",attrs:{"alignItems":"center"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('pui-flex',{staticClass:"pui-attribute-external-link__config-right",attrs:{"alignItems":"center"}},[_c('pui-menu-button',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closePopper = ref.closePopper;
return [_c('pui-button',{attrs:{"title":_vm.$t('common.attribute_external_link.open_upload'),"disabled":_vm.disabled,"block":"","flat":""},on:{"click":function($event){closePopper();
                  _vm.openUploadDialog();}}},[_vm._v(" "+_vm._s(_vm.$t('common.attribute_external_link.open_upload'))+" ")])]}}],null,false,1883579200)})],1)],1):_vm._e(),(_vm.activateValidationMessages && _vm.hasAnyError)?_c('pui-flex',{staticClass:"pui-attribute-external-link__error-message"},[_c('i',{staticClass:"error-icon mdi mdi-alert"}),(_vm.requiredError)?_c('span',{staticClass:"msg"},[_vm._v(_vm._s(_vm.$t('common.attribute_external_link.errors.required')))]):(_vm.minError)?_c('span',{staticClass:"msg"},[_vm._v(_vm._s(_vm.$t('common.attribute_external_link.errors.min_items', { minItems: _vm.minItems })))]):(_vm.maxError)?_c('span',{staticClass:"msg"},[_vm._v(_vm._s(_vm.$t('common.attribute_external_link.errors.max_items', { maxItems: _vm.maxItems })))]):_vm._e()]):_vm._e(),_c('pui-column-container',{directives:[{name:"scrollable",rawName:"v-scrollable.y",modifiers:{"y":true}}],staticClass:"pui-attribute-external-link__column-container",style:(_vm.columnContainerStyle),attrs:{"items":_vm.displayItems,"getItemId":_vm.getItemId,"cols":_vm.colCount,"colClass":"pui-attribute-external-link__item-col","transitionName":"transition-link-item"},on:{"resize":_vm.onResize},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var item = ref.item;
                  var itemIndex = ref.itemIndex;
return [_c('pui-external-link-item',{key:_vm.getItemId(item),attrs:{"item":item,"extendedMode":_vm.extendedMode,"getItemId":_vm.getItemId,"error":_vm.hasAnyError,"isItemBeingDragged":!!_vm.draggingItem,"disabled":_vm.disabled,"isAddButton":_vm.getItemId(item) === _vm.ATTRIBUTE_EXTERNAL_LINK_UPLOAD_BUTTON_ITEM_ID,"isBeforeTargetActive":_vm.additionalActiveBeforeTargetId === _vm.getItemId(item),"isAfterTargetActive":_vm.additionalActiveAfterTargetId === _vm.getItemId(item),"disableBeforeTarget":itemIndex !== 0 && _vm.draggingItemIndex === itemIndex - 1,"disableAfterTarget":_vm.draggingItemIndex === itemIndex + 1},on:{"dndStart":function($event){_vm.draggingItem = item},"dndEnd":function($event){_vm.draggingItem = null},"dndDropBefore":function($event){return _vm.onFileDrop($event, itemIndex)},"dndDropAfter":function($event){return _vm.onFileDrop($event, itemIndex + 1)},"dndMoveBefore":function($event){return _vm.moveDragItemToTarget(item, itemIndex)},"dndMoveAfter":function($event){return _vm.moveDragItemToTarget(item, itemIndex + 1)},"openUploadDialog":function($event){return _vm.openUploadDialog()},"removeItem":function($event){return _vm.removeItem(item, itemIndex)},"changeDragOverBefore":function($event){return _vm.onChangeDragOverBefore(item, $event)},"changeDragOverAfter":function($event){return _vm.onChangeDragOverAfter(item, $event)}}})]}}])}),(_vm.isImporting)?_c('pui-common-progress-linear',{staticClass:"pui-attribute-external-link__upload-progress",attrs:{"value":_vm.importProgressPercent,"message":_vm.$t('common.attribute_external_link.upload_dialog.upload_in_progress', {
            countDone: _vm.nImported,
            countTotal: _vm.totalToImport,
          }),"determinate":""}}):_vm._e(),_c('portal',{attrs:{"selector":_vm.dialogSelector}},[_c('pui-external-link-upload-dialog',{ref:"uploadDialog",attrs:{"attributeLabel":_vm.label,"filterFiles":_vm.filterFiles},on:{"import":_vm.onImport}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }