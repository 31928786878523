<template>
  <pui-button
    :class="{
      'pui-thumbnail-action pui-thumbnail-action__root': true,
      'action-active': active,
      disabled: disabled,
    }"
    :picto="loading ? null : picto"
    :disabled="disabled"
    variant="secondary"
    v-on="$listeners"
  >
    <pui-common-spinner
      v-if="loading"
      :size="20"
      :strokeWidth="2"
      :hideMessage="true"
    ></pui-common-spinner>
  </pui-button>
</template>

<script>
export default {
  name: 'PuiThumbnailAction',
  props: {
    /**
     * Icon for the action
     */
    picto: {
      type: String,
      required: true,
    },
    /**
     * If the action is active
     */
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * Shows a spinner inside the button instead of the picto
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Disables the button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
