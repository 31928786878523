<template>
  <svg
    id="Calque_1"
    data-name="Calque 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 117.23 148.15"
  >
    <path
      d="M116.53,26.88,90.39.74A2.47,2.47,0,0,0,88.63,0H2.53A2.5,2.5,0,0,0,0,2.51V145.65a2.49,2.49,0,0,0,2.5,2.5H114.76a2.49,2.49,0,0,0,2.5-2.5v-117A2.48,2.48,0,0,0,116.53,26.88ZM91.28,8.69l17.38,17.39H91.28Zm21,134.46H5V5H86a2.45,2.45,0,0,0,.22.73l.06.11V28.58a2.5,2.5,0,0,0,2.5,2.5h23.48Z"
      transform="translate(-0.03 -0.01)"
    />
    <path
      d="M38.57,58.76l4.59-4.59a2.5,2.5,0,0,0-3.53-3.54L35,55.23l-4.59-4.6a2.5,2.5,0,0,0-3.54,3.54l4.6,4.59-4.6,4.6a2.5,2.5,0,0,0,3.54,3.53L35,62.3l4.6,4.59a2.5,2.5,0,0,0,3.53-3.53Z"
      transform="translate(-0.03 -0.01)"
    />
    <path
      d="M86.57,58.76l4.59-4.59a2.5,2.5,0,0,0-3.53-3.54L83,55.23l-4.59-4.6a2.5,2.5,0,0,0-3.54,3.54l4.6,4.59-4.6,4.6a2.5,2.5,0,0,0,3.54,3.53L83,62.3l4.6,4.59a2.5,2.5,0,0,0,3.53-3.53Z"
      transform="translate(-0.03 -0.01)"
    />
    <path
      d="M69.42,86c-3.33,0-5.23-2.36-6.76-4.26s-2.42-2.92-4-2.92c-2,0-3.67,1.8-5.26,3.54S50.06,86,47.64,86c-2.66,0-5.13-2.39-7.51-4.7a24.07,24.07,0,0,0-2.71-2.41,10.07,10.07,0,0,0-2.72,2.71,2.5,2.5,0,0,1-4.1-2.86c.78-1.13,3.6-4.82,6.85-4.92,2.1,0,3.89,1.68,6.16,3.88,1.1,1.07,3.12,3,4,3.28a12,12,0,0,0,2.1-2c2-2.18,4.72-5.16,8.94-5.16,4.06,0,6.28,2.77,7.91,4.78,1.31,1.63,2,2.4,2.86,2.4s1.59-.7,2.85-2.3c1.37-1.75,3.25-4.15,6.63-4.15a9.17,9.17,0,0,1,8.29,5.08,2.5,2.5,0,1,1-4.54,2.09,4.19,4.19,0,0,0-3.75-2.17c-.86,0-1.48.68-2.7,2.23C74.81,83.59,72.89,86,69.42,86ZM37.21,78.8Z"
      transform="translate(-0.03 -0.01)"
    />
    <text v-if="extension" :x="x" y="110" :textLength="textLength" :class="computedTextClass">
      {{ extension }}
    </text>
  </svg>
</template>

<script>
const VIEWBOX_WIDTH = 117.23;

export default {
  name: 'PuiCorruptedThumbnail',
  props: {
    /**
     * The text to display inside the thumbnail
     *
     * @type {String}
     * @default null
     */
    extension: {
      type: String,
      default: null,
    },
    /**
     * Extra class for the text element
     *
     * @type {String}
     * @default null
     */
    textClass: {
      type: String,
      default: null,
    },
  },
  computed: {
    /**
     * Viewbox for the svg element
     *
     * @returns {string} the viewbox string
     */
    viewBox() {
      return `0 0 ${VIEWBOX_WIDTH} 148.15`;
    },
    /**
     * @returns {object} class object for the text element
     */
    computedTextClass() {
      return {
        'file-thumbnail-text': true,
        [this.textClass]: this.textClass,
      };
    },
    /**
     * @returns {Number} scaled text length for the extension
     */
    textLength() {
      if (!this.extension) {
        return VIEWBOX_WIDTH;
      }

      return Math.min((this.extension.length / 4) * 40, VIEWBOX_WIDTH);
    },
    /**
     * @returns {Number} x coordinate for the text element so that
     * the actual text will be centered
     */
    x() {
      return VIEWBOX_WIDTH / 2 - this.textLength / 2;
    },
  },
};
</script>
