<template>
  <pui-flex flex="1" class="pui-attribute-tree pui-attribute-tree__root" direction="column">
    <pui-common-spinner v-if="internalIsLoadingRoots || isLoadingRoots" position="fixed" />

    <pui-flex class="pui-attribute-tree pui-attribute-tree__top">
      <pui-flex
        v-if="showLabel"
        class="pui-attribute-tree pui-attribute-tree__label"
        alignItems="center"
      >
        {{ attribute | piivoTranslate }}
      </pui-flex>
    </pui-flex>

    <pui-flex v-if="showSelectAll" class="pui-attribute-tree__select-all-wrapper">
      <pui-checkbox
        :checked="areAllChecked || areSomeChecked"
        :indeterminate="areSomeChecked && !areAllChecked"
        :label="$t('common.attribute_tree.select_all')"
        class="pui-attribute-tree__select-all-checkbox"
        @change="onChangeSelectAll"
      ></pui-checkbox>

      <span
        v-if="attribute.options.displayOptions.showSelectionInfo"
        class="pui-attribute-tree__selection-info"
      >
        {{
          $tc('common.attribute_tree.selection_info', innerSelection.length, {
            count: innerSelection.length,
          })
        }}
      </span>
    </pui-flex>

    <pui-tree
      ref="tree"
      :roots="rootFolders"
      :options="computedTreeOptions"
      class="pui-attribute-tree__tree"
      v-on="computedListeners"
    >
      <template #name="nodeSlotProps">
        <template v-if="nodeSlotProps.node._is_error_node">
          <pui-flex class="pui-attribute-tree__error-node name" direction="column">
            {{ $t('dam.folders.tree.error.message') }}
            <a
              class="pui-attribute-tree__error-node reload-link"
              @click="addChildrenFolders(nodeSlotProps.parentNode)"
              >{{ $t('dam.folders.tree.error.reload') }}</a
            >
          </pui-flex>
        </template>
        <template v-else>
          <slot v-bind="nodeSlotProps" name="nodeNameLabel">
            {{ piivoTranslate(nodeSlotProps.node) }}
          </slot>
        </template>
      </template>

      <template #afterName="nodeSlotProps">
        <slot v-bind="nodeSlotProps" name="afterName" />
        <div v-if="nodeSlotProps.node._isLoading">
          <pui-common-spinner
            ref="masonrySpinner"
            :size="20"
            :strokeWidth="2"
            :hideMessage="true"
            class="folder-spinner"
          />
        </div>
        <slot v-bind="nodeSlotProps" name="afterNameAfterMeta" />
      </template>
    </pui-tree>
  </pui-flex>
</template>

<script>
import Vue from 'vue';

import { ensureArray } from '../../../../utils/array';

const getNodeErrorId = (nodeId) => `${nodeId}_error`;

const generateErrorNode = (node) => ({
  itemId: getNodeErrorId(node._tree_node_id),
  _is_error_node: true,
  options: {
    hideCheckbox: true,
    noSelection: true,
    contentWrapperClass: {
      'pui-attribute-tree__error-node-wrapper': true,
    },
  },
});

/**
 * @returns {boolean} if the node is a lazy load node that only contains
 * the link to get the node details
 */
const isLazyLoadNode = (node) => !!Object.hasOwnProperty.call(node, 'href');

async function loadNodes(nodes) {
  // TODO: lazy load href
  return nodes;
}

/**
 * @param {*} node the node or identifier
 * @returns the identifier
 */
function getTreeId(node) {
  const id = node.itemId || node.alias;
  if (typeof id !== 'string') {
    throw new new Error('Unknown node id type')();
  }

  return id;
}

export default {
  name: 'PuiAttributeTree',
  components: {},
  props: {
    /**
     * Options for the tree
     *
     * @type {Object}
     * @default {}
     */
    options: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Disables editing
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Shows the label of the attribute
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
    /**
     * The attribute
     */
    attribute: {
      type: Object,
      required: true,
    },
    /**
     * The selected nodes
     */
    selection: {
      type: null,
      default: () => [],
    },
    /**
     * Load function for paginated options.
     *
     * @type {(attribute: object, options: { context?: object }) => Promise<object[]|null>}
     */
    loadRootsFunction: {
      type: Function,
      default: null,
    },
    /**
     * Is loading roots. Set to true if `loadRootsFunction`
     */
    isLoadingRoots: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalIsLoadingRoots: true,
      rootFolders: [],
      innerSelection: [],
      checkedNodeIDs: {},
      areAllChecked: false,
    };
  },
  computed: {
    /**
     * @returns {object} options for the Tree component
     */
    computedTreeOptions() {
      return {
        keyboardNavigation: true,
        checkbox: this.attribute.options && this.attribute.options.multiSelect,
        multiSelect: this.attribute.options && this.attribute.options.multiSelect,
        checkChangeOnNodeClick: true,
        enableUpperRecursiveSelection: true,
        enableLowerRecursiveSelection: true,
        disabled: this.disabled,
        ...this.options,
      };
    },
    /**
     * @returns {object} all the listeners for the Tree componenet
     */
    computedListeners() {
      return {
        ...this.$listeners,
        toggleExpandedNode: this.onNodeExpansionToggled,
        // Intermediate method to properly pass params
        checkChange: this.onCheckChange,
      };
    },
    /**
     * @returns {boolean} if the select all button should be displayed
     */
    showSelectAll() {
      return this.attribute.options && this.attribute.options.showSelectAll;
    },
    /**
     * @returns {boolean} if some nodes are checked
     */
    areSomeChecked() {
      return Object.values(this.checkedNodeIDs).some((checked) => !!checked);
    },
  },
  watch: {
    /**
     * Update our inner value to match the prop
     */
    selection(newSelection) {
      this.syncFromSelection(newSelection);
    },
  },
  methods: {
    // FILTERS
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    // TREE
    /**
     * Loads the tree roots
     */
    async loadRoots() {
      const roots =
        this.attribute.parameters &&
        this.attribute.parameters.links &&
        Array.isArray(this.attribute.parameters.links) &&
        this.attribute.parameters.links.length
          ? this.attribute.parameters.links
          : null;

      if (!this.loadRootsFunction && !roots) {
        this.$emit('error', 'root', new Error('Did not receive any parameters.links'));
        this.internalIsLoadingRoots = false;
        return;
      }

      this.loadingRoots = true;

      try {
        const rootFolders = [];

        if (Array.isArray(roots) && isLazyLoadNode(roots[0])) {
          const apiRoots = await loadNodes(roots);
          rootFolders.push(...apiRoots.map((node) => this.mapNode(node)));
        } else if (Array.isArray(roots)) {
          const clonedRoots = JSON.parse(JSON.stringify(this.attribute.parameters.links));
          rootFolders.push(...clonedRoots.map((node) => this.mapNode(node)));
        } else if (this.loadRootsFunction) {
          const apiRoots = await this.loadRootsFunction(this.attribute);
          rootFolders.push(...apiRoots.map((node) => this.mapNode(node)));
        }

        this.rootFolders = rootFolders;

        this.$emit('loadedFolders', rootFolders);

        if (this.options.autoExpandRoots) {
          // Wait for roots to be passed to tree
          await this.$nextTick();
          this.rootFolders.forEach((root) => {
            this.expandFolder(getTreeId(root), null);
          });
        }

        // Wait for tree to receive roots
        await this.$nextTick();
        // Init the selection once the tree is rendered
        this.syncFromSelection(this.selection);
      } catch (error) {
        console.error(error);
        this.$emit('error', 'root', error);
      }

      this.internalIsLoadingRoots = false;
    },
    /**
     * Function to generate technical property on node
     * @param {Object} node to add technical properties
     * @returns {Object} node - node with technical properties
     */
    mapNode(node) {
      // Technical id for tree
      this.$set(node, '_tree_node_id', getTreeId(node));
      // Property if node is currently loading children
      this.$set(node, '_isLoading', false);
      // Property if node children call is in error
      this.$set(node, '_hasError', false);

      this.$set(node, 'options', node.options || {});

      // Property to display folder icons if have children
      const hasChildren =
        Object.hasOwnProperty.call(node, 'children') &&
        Array.isArray(node.children) &&
        node.children.length;
      this.$set(node.options, 'hasChildren', hasChildren);

      // Preserve the original links under a separate property
      this.$set(node, '_childrenLinks', node.children || []);

      // Map the real tree node children right away if they
      // are not lazy load nodes
      if ((node.children || []).length && !isLazyLoadNode(node.children[0])) {
        const mappedChildren = (node.children || []).map((child) => this.mapNode(child));
        this.$set(node, 'children', mappedChildren);
      } else {
        this.$set(node, 'children', []);
      }

      this.$set(node.options, 'contentWrapperClass', {
        [node.options.contentWrapperClass]:
          typeof node.options.contentWrapperClass === 'string' && !!node.options.contentWrapperClass
            ? node.options.contentWrapperClass
            : '',
        ...(typeof node.options.contentWrapperClass === 'object' &&
        !!node.options.contentWrapperClass
          ? node.options.contentWrapperClass
          : {}),
        'folder-tree__node': true,
        checked: false,
      });
      this.$set(node.options, 'hideCheckbox', false);
      this.$set(node.options, 'disableCheckbox', false);
      return node;
    },
    /**
     * Loads children of the node from the api
     * into the node's children array
     *
     * @param {Object} node - node to add children to
     * @returns {Promise}
     */
    async addChildrenFolders(node) {
      node._hasError = false;
      node._isLoading = true;

      // No pagination for now: remove all children and just reload them again
      node.children.splice(0, node.children.length);

      try {
        const children = await loadNodes(node._childrenLinks);

        if (!children.length) {
          throw new Error(
            `Attempted to load children of ${this.piivoTranslate(node)}:${getTreeId(
              node
            )}, but received none`
          );
        }

        // Generate technical props on each subFolder
        const loadedChildren = children.map((item) => this.mapNode(item));

        node.children.push(...loadedChildren);
        this.$emit('loadedFolders', loadedChildren);

        loadedChildren.forEach((child) => {
          if (this.isNodeChecked(node)) {
            // Mark the children as checked if parent is already checked
            this.$refs.tree.setNodeIDSelectionState(child._tree_node_id, true);
            this.$set(this.checkedNodeIDs, child._tree_node_id, true);
          }

          // Since we just loaded these children and mapped them to new objects,
          // the grandchildren are not loaded
          // So we have to reset the expanded state of the children
          this.$refs.tree.setNodeIdExpansionState(child, false);
        });
      } catch (error) {
        node._hasError = true;
        const errorNode = this.mapNode(generateErrorNode(node));

        node.children.splice(0, node.children.length);
        node.children.push(errorNode);
        this.$emit('error', 'children', error);
      }

      node._isLoading = false;
    },
    /**
     * Function calls when node expansion toggled
     * @param {Object} node expansion toggled
     * @param {Boolean} nodeExpansionState - value of expansion
     * @param {Object} expansionState - key value with each expansion node state
     * @returns {void}
     */
    async onNodeExpansionToggled(node, nodeExpansionState, expansionState) {
      if (!nodeExpansionState) {
        // If node is now closed there is nothing to do
        return;
      }

      if (node.hasChildren && node._childrenLinks && isLazyLoadNode(node._childrenLinks[0])) {
        // Only attempt to load children if they do exist
        await this.addChildrenFolders(node);
      }
    },
    /**
     * Handles a toggle of a node selection
     *
     * @param {object} node - the toggled node
     * @param {boolean} nodeIsChecked - the new state of the node
     * @param {object} checkedNodeIDs - map of node id to check state
     * @param {object} indeterminateNodeIDs - map of node id to indeterminate state
     * @param {object[]} selection - array of selected nodes
     */
    async onCheckChange(node, nodeIsChecked, checkedNodeIDs, indeterminateNodeIDs, selection) {
      this.checkedNodeIDs = { ...checkedNodeIDs };
      this.innerSelection = [...selection];

      this.$emit('checkChange', this.checkedNodeIDs, selection);

      if (
        nodeIsChecked &&
        node.hasChildren &&
        node._childrenLinks &&
        isLazyLoadNode(node._childrenLinks[0])
      ) {
        await this.loadAndSelectRecursively(node, true);

        this.checkedNodeIDs = { ...this.$refs.tree.getCheckedState() };
        this.innerSelection = [...this.$refs.tree.getSelection()];
        this.$emit('checkChange', this.checkedNodeIDs, this.innerSelection);
      }

      this.updateAreAllChecked();
    },
    /**
     * Loads and selects all the children of the node
     *
     * @param {object} node - the node to select
     * @param {boolean} [isRootHandler=false] - if this node is the root node
     * that is being selected
     */
    async loadAndSelectRecursively(node, isRootHandler = false) {
      if (isRootHandler) {
        this.internalIsLoadingRoots = true;
      }

      if (node.options.hasChildren) {
        // Load the children if none are loaded yet
        if (!node.children.length) {
          this.$refs.tree.setNodeExpansionState(node, true);
          await this.onNodeExpansionToggled(node, true, this.$refs.tree.getExpansionState());
        }

        // Select each child, and then its children
        for (const childNode of node.children) {
          // Set the child's selection to match the checked one
          this.$refs.tree.setNodeIDSelectionState(childNode._tree_node_id, true);
          this.$refs.tree.setNodeIsIndeterminate(childNode._tree_node_id, false);

          // Recursively load and apply state inside the child
          await this.loadAndSelectRecursively(childNode, false);
        }
      }

      if (isRootHandler) {
        this.internalIsLoadingRoots = false;
      }
    },
    /**
     * Syncs 'areAllChecked' with the the current selection
     */
    updateAreAllChecked() {
      // Fast opposite verification: if nothing is checked "areAllChecked" has to be false
      if (!Object.values(this.checkedNodeIDs).some((checked) => !!checked)) {
        this.areAllChecked = false;
        return;
      }

      // The whole tree is checked if all the roots are checked:
      // Since selection is recursive: if a root is checked
      // that means all its children are also checked
      this.areAllChecked = this.rootFolders.every(
        (root) => !!this.checkedNodeIDs[root._tree_node_id]
      );
    },
    /**
     * Handles a change of the "select all" checkbox
     *
     * @param {boolean} checked - if all the nodes should be checked
     */
    async onChangeSelectAll(checked) {
      this.areAllChecked = checked;

      if (checked) {
        // Select all the root nodes recursively
        this.internalIsLoadingRoots = true;
        for (const rootNode of this.rootFolders) {
          this.$refs.tree.setNodeIDSelectionState(rootNode._tree_node_id, true);
          this.$refs.tree.setNodeIsIndeterminate(rootNode._tree_node_id, false);
          await this.loadAndSelectRecursively(rootNode, false);
        }
        this.internalIsLoadingRoots = false;
      } else {
        // Uncheck all nodes
        Object.keys(this.checkedNodeIDs).forEach((nodeId) => {
          this.$refs.tree.setNodeIDSelectionState(nodeId, false);
          this.$refs.tree.setNodeIsIndeterminate(nodeId, false);
        });
      }

      // Sync internal state with tree, and then emit change
      this.checkedNodeIDs = { ...this.$refs.tree.getCheckedState() };
      this.innerSelection = [...this.$refs.tree.getSelection()];
      this.$emit('checkChange', this.checkedNodeIDs, this.innerSelection);
    },
    /**
     * Sync the selection to our inner value
     *
     * @param {string|object[]} the selected node ids, or the nodes
     */
    syncFromSelection(selection) {
      if (!selection) {
        return;
      }

      // Reset the current selection
      Object.keys(this.$refs.tree.getCheckedState()).forEach((id) => {
        this.$refs.tree.setNodeIDSelectionState(id, false);
      });
      Object.keys(this.$refs.tree.getIndeterminateState()).forEach((id) => {
        this.$refs.tree.setNodeIsIndeterminate(id, false);
      });

      // Then apply the prop selection
      const normalizedSelection = ensureArray(selection);
      normalizedSelection.forEach((item) => {
        // Recursively select the nodes via the exposed tree method
        // so that indeterminate states are also updated
        if (typeof item === 'string') {
          this.$refs.tree.setNodeSelectionRecursive(item, true);
        } else if (typeof item === 'object') {
          this.$refs.tree.setNodeSelectionRecursive(getTreeId(item), true);
        }
      });

      // Retrieve the changes
      this.checkedNodeIDs = { ...this.$refs.tree.getCheckedState() };
      this.innerSelection = [...this.$refs.tree.getSelection()];

      // Update 'areAllChecked' since our value just changed
      this.updateAreAllChecked();
    },
    // EXPOSED METHODS
    /**
     * @returns {string} the selection state of the node
     */
    isNodeChecked(node) {
      return !!this.checkedNodeIDs[getTreeId(node)];
    },
  },
};
</script>
