<template>
  <pui-button
    class="pui-thumbnail-main-action pui-thumbnail-main-action__root"
    variant="secondary"
    v-on="$listeners"
  >
    <slot></slot>
  </pui-button>
</template>

<script>
export default {
  name: 'PuiThumbnailMainAction',
};
</script>
