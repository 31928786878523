import Vue from 'vue';

import { PersistKeys } from '../../../core/store/plugins/persistence';

export const NAMESPACE = 'sessionSettings';

export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_IN_CATEGORY = 'SET_IN_CATEGORY';

export const REMOVE_CATEGORY_MUTATION = 'REMOVE_CATEGORY';
export const CLEAR_ALL_CATEGORIES_MUTATION = 'CLEAR_ALL_CATEGORIES';

const SET_CATEGORY_MUTATION = 'SET_CATEGORY_MUTATION';
const SET_IN_CATEGORY_MUTATION = 'SET_IN_CATEGORY_MUTATION';
const SET_SESSION_STATE = 'SET_USER';

const CATEGORY_STATE_PREFIX = 'setting_';

const getCategoryStateKey = (categoryName) => `${CATEGORY_STATE_PREFIX}${categoryName}`;

/**
 * Session setting store module.
 */
export const SessionSettingsModule = {
  namespaced: true,
  state: {
    [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
  },

  getters: {
    getSessionSettings: (state) => (userName) => state[userName],
    getCategory: (state, getters) => (userName, categoryName) => {
      const sessionSettings = getters.getSessionSettings(userName);
      return sessionSettings ? sessionSettings[getCategoryStateKey(categoryName)] : undefined;
    },
    getInCategory: (state, getters) => (userName, categoryName, key) => {
      const categoryState = getters.getCategory(userName, categoryName);
      return categoryState ? categoryState[key] : undefined;
    },
  },

  actions: {
    /**
     * Sets a value in a category
     *
     * @param {object} context - store context
     * @param {Function} context.commit - store commit function
     * @param {object} context.state - store state
     * @param {object} payload - action payload
     * @param {string} payload.userName - the session key
     * @param {string} payload.categoryName - the key for the category
     * @param {string} payload.key - the key to save
     * @param {*} payload.value - the value to save
     * @returns {void}
     */
    [SET_CATEGORY]({ commit, state }, { userName, categoryName, categoryState }) {
      if (!Object.hasOwnProperty.call(state, userName)) {
        commit(SET_SESSION_STATE, { userName, categoryState });
      } else {
        commit(SET_CATEGORY_MUTATION, { userName, categoryName, categoryState });
      }
    },
    /**
     * Sets a value in a category
     *
     * @param {object} context - store context
     * @param {Function} context.commit - store commit function
     * @param {object} context.state - store state
     * @param {object} payload - action payload
     * @param {string} payload.userName - the session key
     * @param {string} payload.categoryName - the key for the category
     * @param {string} payload.key - the key to save
     * @param {*} payload.value - the value to save
     * @returns {void}
     */
    [SET_IN_CATEGORY]({ commit, state }, { userName, categoryName, key, value }) {
      if (!Object.hasOwnProperty.call(state, userName)) {
        commit(SET_SESSION_STATE, {
          userName,
          userState: { [getCategoryStateKey(categoryName)]: { [key]: value } },
        });
      } else if (!Object.hasOwnProperty.call(state[userName], getCategoryStateKey(categoryName))) {
        commit(SET_CATEGORY_MUTATION, {
          userName,
          categoryName,
          categoryState: { [key]: value },
        });
      } else {
        commit(SET_IN_CATEGORY_MUTATION, { userName, categoryName, key, value });
      }
    },
  },

  mutations: {
    /**
     * Sets the session state
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.userName - the session key
     * @param {object} payload.userState - the state to save
     */
    [SET_SESSION_STATE](state, { userName, userState }) {
      Vue.set(state, userName, userState);
    },
    /**
     * Sets a session's category
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.userName - the session key
     * @param {string} payload.categoryName - the key for the category
     * @param {object} payload.categoryState - the category state
     */
    [SET_CATEGORY_MUTATION](state, { userName, categoryName, categoryState }) {
      Vue.set(state[userName], getCategoryStateKey(categoryName), categoryState);
    },
    /**
     * Sets a value in a session's category
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.userName - the session key
     * @param {string} payload.categoryName - the key for the category
     * @param {object} payload.key - the key to save
     * @param {object} payload.value - the state to save
     */
    [SET_IN_CATEGORY_MUTATION](state, { userName, categoryName, key, value }) {
      Vue.set(state[userName][getCategoryStateKey(categoryName)], key, value);
    },
    /**
     * Removes a session's category
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.userName - the session key
     * @param {string} payload.categoryName - the key for the category
     */
    [REMOVE_CATEGORY_MUTATION](state, { userName, categoryName }) {
      Vue.delete(state[userName], getCategoryStateKey(categoryName));
    },
    /**
     * Clears all session's categories
     *
     * @param {object} state - Store State
     * @param {string} payload.userName - the session key
     */
    [CLEAR_ALL_CATEGORIES_MUTATION](state, { userName }) {
      Object.keys(state[userName]).forEach((key) => {
        if (key.startsWith(CATEGORY_STATE_PREFIX)) {
          Vue.delete(state, getCategoryStateKey(key));
        }
      });
    },
  },
};
