/**
 * Local states for an upload task
 */
export const UploadStates = {
  CREATING: 'creating',
  UPLOADING_FILES: 'uploading_files',
  PROCESSING: 'processing',
  DONE_SUCCESS: 'done_success',
  DONE_ERROR: 'done_error',
};
