<template>
  <pui-common-drop-zone
    :dragOverCb="onDragOver"
    :dragEnterCb="onDragEnter"
    :loading="isProcessingDrop"
    :disabled="disabled"
    class="pui-attribute-external-link pui-attribute-external-link__root"
    @change="onDropFiles"
  >
    <template #message>
      <pui-flex
        class="pui-attribute-external-link pui-attribute-external-link__content"
        direction="column"
      >
        <pui-flex v-if="!hideTopMenu" class="pui-attribute-external-link__top">
          <pui-flex v-if="showLabel" class="pui-attribute-external-link__label" alignItems="center">
            {{ label }}
          </pui-flex>

          <pui-flex class="pui-attribute-external-link__config-right" alignItems="center">
            <pui-menu-button>
              <template #default="{ closePopper }">
                <pui-button
                  :title="$t('common.attribute_external_link.open_upload')"
                  :disabled="disabled"
                  block
                  flat
                  @click="
                    closePopper();
                    openUploadDialog();
                  "
                >
                  {{ $t('common.attribute_external_link.open_upload') }}
                </pui-button>
              </template>
            </pui-menu-button>
          </pui-flex>
        </pui-flex>

        <pui-flex
          v-if="activateValidationMessages && hasAnyError"
          class="pui-attribute-external-link__error-message"
        >
          <i class="error-icon mdi mdi-alert"></i>
          <span v-if="requiredError" class="msg">{{
            $t('common.attribute_external_link.errors.required')
          }}</span>
          <span v-else-if="minError" class="msg">{{
            $t('common.attribute_external_link.errors.min_items', { minItems })
          }}</span>
          <span v-else-if="maxError" class="msg">{{
            $t('common.attribute_external_link.errors.max_items', { maxItems })
          }}</span>
        </pui-flex>

        <pui-column-container
          v-scrollable.y
          :style="columnContainerStyle"
          :items="displayItems"
          :getItemId="getItemId"
          :cols="colCount"
          colClass="pui-attribute-external-link__item-col"
          class="pui-attribute-external-link__column-container"
          transitionName="transition-link-item"
          @resize="onResize"
        >
          <template #default="{ item, itemIndex }">
            <pui-external-link-item
              :key="getItemId(item)"
              :item="item"
              :extendedMode="extendedMode"
              :getItemId="getItemId"
              :error="hasAnyError"
              :isItemBeingDragged="!!draggingItem"
              :disabled="disabled"
              :isAddButton="getItemId(item) === ATTRIBUTE_EXTERNAL_LINK_UPLOAD_BUTTON_ITEM_ID"
              :isBeforeTargetActive="additionalActiveBeforeTargetId === getItemId(item)"
              :isAfterTargetActive="additionalActiveAfterTargetId === getItemId(item)"
              :disableBeforeTarget="itemIndex !== 0 && draggingItemIndex === itemIndex - 1"
              :disableAfterTarget="draggingItemIndex === itemIndex + 1"
              @dndStart="draggingItem = item"
              @dndEnd="draggingItem = null"
              @dndDropBefore="onFileDrop($event, itemIndex)"
              @dndDropAfter="onFileDrop($event, itemIndex + 1)"
              @dndMoveBefore="moveDragItemToTarget(item, itemIndex)"
              @dndMoveAfter="moveDragItemToTarget(item, itemIndex + 1)"
              @openUploadDialog="openUploadDialog()"
              @removeItem="removeItem(item, itemIndex)"
              @changeDragOverBefore="onChangeDragOverBefore(item, $event)"
              @changeDragOverAfter="onChangeDragOverAfter(item, $event)"
            ></pui-external-link-item>
          </template>
        </pui-column-container>

        <pui-common-progress-linear
          v-if="isImporting"
          :value="importProgressPercent"
          :message="
            $t('common.attribute_external_link.upload_dialog.upload_in_progress', {
              countDone: nImported,
              countTotal: totalToImport,
            })
          "
          class="pui-attribute-external-link__upload-progress"
          determinate
        ></pui-common-progress-linear>

        <portal :selector="dialogSelector">
          <pui-external-link-upload-dialog
            ref="uploadDialog"
            :attributeLabel="label"
            :filterFiles="filterFiles"
            @import="onImport"
          ></pui-external-link-upload-dialog
        ></portal>
      </pui-flex>
    </template>
  </pui-common-drop-zone>
</template>

<script>
import { getAllItemsFromDrop } from 'piivo-ui/src/utils/file';
import Vue from 'vue';

import { filterFiles } from '../../../../core/helpers/fileHelpers';
import { move } from '../../../../utils/array';
import {
  ATTRIBUTE_EXTERNAL_LINK_ITEM_META_HEIGHT_PX,
  ATTRIBUTE_EXTERNAL_LINK_UPLOAD_BUTTON_ITEM_ID,
} from '../../constants';
import { FILE_DROP_EFFECT } from '../../constants/attributes';
import { validateMaxItems, validateMinItems } from '../../helpers/attributeValidators';
import puiExternalLinkItem from './ExternalLinkItem';
import puiExternalLinkUploadDialog from './ExternalLinkUploadDialog';

/**
 * @returns {item[]} the items without the placeholder objects for
 * files that are are to waiting to be uploaded
 */
export const getActualItems = (items) => items.filter((i) => !i._isTempFile);

export default {
  name: 'PuiAttributeExternalLink',
  components: {
    puiExternalLinkUploadDialog,
    puiExternalLinkItem,
  },
  inject: ['dialogSelector'],
  props: {
    /**
     * The attribute
     */
    attribute: {
      type: Object,
      required: true,
    },
    /**
     * The files uploaded to this attribute
     */
    files: {
      type: Array,
      default: () => [],
    },
    /**
     * Displays more columns, show file names
     */
    extendedMode: {
      type: Boolean,
      default: () => false,
    },
    /**
     * Disables editing
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Shows validation messages, if needed
     */
    activateValidationMessages: {
      type: Boolean,
      default: true,
    },
    /**
     * Shows the label of the attribute
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
    /**
     * Import function for external link.
     * Receives an array of objects in which to iterate to upload, returns all the imported files
     *
     * @type {(
     * attribute: Attribute,
     * tempFiles: F[],
     * onFileUploadedCb: (tempFile: F, f: any) => void,
     * provideCancelCb: (cancelCb: (tempId?: string) => void) => void
     * ) => Promise<object[]>}
     */
    importFiles: {
      type: Function,
      required: true,
    },
    /**
     * The number of columns of files to show when 'extendedMode' is disabled
     */
    retractedColCount: {
      type: Number,
      default: 4,
    },
    /**
     * The number of columns of files to show when 'extendedMode' is enabled
     */
    extendedColCount: {
      type: Number,
      default: 8,
    },
    /**
     * Hides the top menu that contains the label and import menu button
     * (not the error messages)
     * TODO: Added for #17745, to remove when #16747 is done
     */
    hideTopMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerFiles: this.files && Array.isArray(this.files) ? this.files : [],
      containerWidth: -1,
      /**
       * Counter for unique temporary ids
       */
      lastUploadFileId: 1, // Start at 1 to prevent falsy comparisons
      lastDropFileId: 1,
      draggingItem: null,
      isProcessingDrop: false,
      currentImports: [],
      ATTRIBUTE_EXTERNAL_LINK_UPLOAD_BUTTON_ITEM_ID,
      currentDragOverBeforeId: null,
      currentDragOverAfterId: null,
    };
  },
  computed: {
    /**
     * @returns {string} label of the attribute
     */
    label() {
      return this.piivoTranslate(this.attribute);
    },
    /**
     * @returns {number} the amount of file columns to display
     */
    colCount() {
      return this.extendedMode ? this.extendedColCount : this.retractedColCount;
    },
    /**
     * @returns {number} the max amount of lines of files
     * that should be shown before scrolling
     */
    maxLines() {
      // Falsy values should be ignored
      return this.attribute.options.rowCount || -1;
    },
    /**
     * @returns {number} the max height for the column container
     */
    maxHeight() {
      if (this.maxLines <= 0 || this.containerWidth <= 0) {
        return null;
      }

      const itemHeight =
        Math.round(this.containerWidth / this.colCount) +
        (this.extendedMode ? ATTRIBUTE_EXTERNAL_LINK_ITEM_META_HEIGHT_PX : 0) +
        10;
      return itemHeight * this.maxLines;
    },
    /**
     * @returns {object} style object for the column container
     */
    columnContainerStyle() {
      if (!this.maxHeight) {
        return {};
      }

      return {
        maxHeight: `${this.maxHeight}px`,
      };
    },
    /**
     * @returns {object[]} array of items to render
     */
    displayItems() {
      return [
        ...this.innerFiles,
        {
          id: ATTRIBUTE_EXTERNAL_LINK_UPLOAD_BUTTON_ITEM_ID,
        },
      ];
    },
    /**
     * @returns {number} the index of the dragging item or -1 if not
     * dragging any item
     */
    draggingItemIndex() {
      if (!this.draggingItem) {
        return -1;
      }
      const draggingItemId = this.getItemId(this.draggingItem);
      return this.innerFiles.findIndex((f) => this.getItemId(f) === draggingItemId);
    },
    /**
     * @returns {string} the id of the item whose "before" target should
     * be displayed as active
     */
    additionalActiveBeforeTargetId() {
      if (!this.currentDragOverAfterId) {
        return null;
      }
      const dragIndex = this.innerFiles.findIndex(
        (f) => this.getItemId(f) === this.currentDragOverAfterId
      );
      if (dragIndex === -1) {
        return null;
      }
      const item = this.innerFiles[dragIndex + 1];
      if (item) {
        return this.getItemId(item);
      }
      return null;
    },
    /**
     * @returns {string} the id of the item whose "after" target should
     * be displayed as active
     */
    additionalActiveAfterTargetId() {
      if (!this.currentDragOverBeforeId) {
        return null;
      }
      const dragIndex = this.innerFiles.findIndex(
        (f) => this.getItemId(f) === this.currentDragOverBeforeId
      );
      if (dragIndex === -1) {
        return null;
      }
      const item = this.innerFiles[dragIndex - 1];
      if (item) {
        return this.getItemId(item);
      }
      return null;
    },
    // IMPORTS
    /**
     * @returns {boolean} if an import is in progress
     */
    isImporting() {
      return this.currentImports.some((i) => i.nImported < i.totalToImport);
    },
    /**
     * @returns {number} the total amount of files to import
     */
    totalToImport() {
      return this.currentImports.reduce((acc, i) => acc + i.totalToImport, 0);
    },
    /**
     * @returns {number} the total amount of files that were imported
     */
    nImported() {
      return this.currentImports.reduce((acc, i) => acc + i.nImported, 0);
    },
    /**
     * @returns {Number} percentage value of the import progress
     */
    importProgressPercent() {
      if (!this.isImporting) {
        return 0;
      }

      return (this.nImported / this.totalToImport) * 100;
    },
    // VALIDATION
    /**
     * @returns {number} the minimum amount of files the user should
     * add to the attribute
     */
    minItems() {
      return this.attribute.options.minimum;
    },
    /**
     * @returns {number} the maximul amount of files the user can
     * add to the attribute
     */
    maxItems() {
      return this.attribute.options.maximum;
    },
    /**
     * @returns {boolean} if the amount of files is less than the minimum
     */
    minError() {
      return !validateMinItems(this.innerFiles, this.minItems);
    },
    /**
     * @returns {boolean} if the amount of files is over the maximum
     */
    maxError() {
      return !validateMaxItems(this.innerFiles, this.maxItems);
    },
    /**
     * @returns {boolean} if there isn't at least 1 file
     */
    requiredError() {
      return this.attribute.options.required && !validateMinItems(this.innerFiles, 1);
    },
    /**
     * @returns {boolean} if at least 1 error is active
     */
    hasAnyError() {
      return this.requiredError || this.maxError || this.minError;
    },
  },
  watch: {
    /**
     * Update our inner value to be the prop array
     */
    files(newFiles) {
      this.innerFiles = newFiles && Array.isArray(newFiles) ? newFiles : [];
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    /**
     * @param {Object} item - a file of the attribute
     * @returns {string} unique id for an item
     */
    getItemId(item) {
      return item.id;
    },
    /**
     * Called when the column container resises
     *
     * @param {*} _ - ignore param
     * @param {Number} containerWidth - width of the column container
     */
    onResize(_, containerWidth) {
      this.containerWidth = containerWidth;
    },
    /**
     * Removes the item from the list
     *
     * @param {object} item - the item to remove
     * @param {number} itemIndex - the index of the item to remove
     */
    removeItem(item, itemIndex) {
      if (item._abortImportFn) {
        item._abortImportFn();
      }
      this.innerFiles.splice(itemIndex, 1);
      this.$emit('change', this.innerFiles);
    },
    // DND ITEM
    /**
     * Moves the dragging item to the dropped index
     *
     * @param {object} target - the item on which we dropped
     * @param {number} targetIndex - the index of the target
     */
    moveDragItemToTarget(target, targetIndex) {
      // Reset the dragovers BEFORE we trigger the change, otherwise
      // the computed additional dragovers might affect the moving items:
      // if the moving items' props change, the move won't be animated
      this.currentDragOverBeforeId = null;
      this.currentDragOverAfterId = null;

      const source = this.draggingItem;
      if (this.getItemId(target) === this.getItemId(source)) {
        return;
      }

      // Get the source index at the moment of the drop,
      // in case items were moved around while dragging
      const sourceIndex = this.innerFiles.findIndex(
        (i) => this.getItemId(i) === this.getItemId(source)
      );

      move(sourceIndex, targetIndex, this.innerFiles);

      this.$emit('change', this.innerFiles);
    },
    /**
     * Triggered on a drag over change of the "before" target of an item
     *
     * @param {object} item - the item whose target was dragged over
     * @param {boolean} isDragOver - if the target is being dragged over
     */
    onChangeDragOverBefore(item, isDragOver) {
      this.currentDragOverBeforeId = isDragOver ? this.getItemId(item) : null;
    },
    /**
     * Triggered on a drag over change of the "after" target of an item
     *
     * @param {object} item - the item whose target was dragged over
     * @param {boolean} isDragOver - if the target is being dragged over
     */
    onChangeDragOverAfter(item, isDragOver) {
      this.currentDragOverAfterId = isDragOver ? this.getItemId(item) : null;
    },
    // UPLOAD
    /**
     * Handles a dragover event on the drop zone
     *
     * @param {DragEvent} e - the drag event
     * @returns {Boolean} if the drag should be allowed
     */
    onDragOver(e) {
      if (this.draggingItem) {
        return false;
      }

      // We must add an effect for the drop to receive files
      // Make sure the effect is not an effect allowed when dragging an item
      // To prevent dropping an item back on ourselves
      e.dataTransfer.dropEffect = FILE_DROP_EFFECT;
      e.preventDefault();
      return true; // Return true to DropZone to enable styles, drop
    },
    /**
     * Handles a dragenter event on the drop zone
     *
     * @param {DragEvent} e - the drag event
     * @returns {Boolean} if the drag should be allowed
     */
    onDragEnter(e) {
      // Only apply dragover styles when NOT dragging an item
      return !this.draggingItem; // Return true to DropZone to enable styles, drop
    },
    /**
     * @param {File[]} files - files to filter
     * @returns {File[]} valid files that can be uploaded to this attribute
     */
    filterFiles(files) {
      const { maxSize, supportedExtensions } =
        this.attribute.options.valueImportConfiguration.typeOptions || {};

      return filterFiles(files, { maxSize: maxSize || 0, extensions: supportedExtensions || [] });
    },
    /**
     * Opens the import dialog with the files dropped on the main
     * drop zone
     *
     * @param {File[]} files - the array of files that were dropped
     */
    onDropFiles(files) {
      const filteredFiles = this.filterFiles(files);
      const showFilterWarning = files.length !== filteredFiles.length;

      this.openUploadDialog(filteredFiles, showFilterWarning, { insertIndex: -1 });
    },
    /**
     * Opens the upload dialog
     *
     * @param {File[]} files - array of files to prefill the dialog with
     * @param {boolean} showFilterWarning - if should display the warning
     * that files were filtered
     * @param {object} cbOpts - options to pass back through the import event
     * @param {number} opts.insertIndex - the index at which to insert the files
     */
    openUploadDialog(files = [], showFilterWarning = false, cbOpts = {}) {
      // Map the File objects to custom objects for the upload dialog
      const mappedFiles = files.map((f) => ({
        id: `dropped_file_${++this.lastDropFileId}`,
        name: f.name,
        size: f.size,
        type: f.type,
        blob: f,
      }));

      if (this.$refs.uploadDialog) {
        this.$refs.uploadDialog.open(mappedFiles, showFilterWarning, cbOpts);
      }
    },
    /**
     * Opens the import dialog with the files dropped on an item
     *
     * @param {DropEvent} e - the drop event
     * @param {number} insertIndex - the index at which to insert the files
     */
    async onFileDrop(e, insertIndex) {
      this.isProcessingDrop = true;

      const allFiles = await getAllItemsFromDrop(e, true);
      const filteredFiles = this.filterFiles(allFiles);

      const showFilterWarning = allFiles.length !== filteredFiles.length;
      this.openUploadDialog(filteredFiles, showFilterWarning, { insertIndex });
      this.isProcessingDrop = false;
    },
    /**
     * Forwards the import event
     *
     * @param {object[]} filesToImport - files to upload to the attribute
     * @param {object} opts - import options
     * @param {number} opts.insertIndex - index at which to insert the files
     */
    async onImport(filesToImport, opts) {
      const mergedOpts = {
        insertIndex: -1,
        ...(opts || {}),
      };

      const currentImport = {
        totalToImport: filesToImport.length,
        nImported: 0,
        abortFn: null,
      };
      this.currentImports.push(currentImport);

      // Map all the files to temporary items and insert
      // them right away into the grid
      const tempFiles = filesToImport.map((file) => {
        // Map each of the upload dialog objects
        // to custom objects, and retrieve the File blob
        const id = ++this.lastUploadFileId;
        return {
          id,
          file: file.blob,
          name: file.blob.name,
          _isTempFile: true,
          _abortImportFn: () => {
            if (currentImport.abortFn) {
              currentImport.abortFn(id);
            }
          },
        };
      });

      if (mergedOpts.insertIndex !== -1) {
        this.innerFiles.splice(mergedOpts.insertIndex, 0, ...tempFiles);
      } else {
        this.innerFiles.push(...tempFiles);
      }

      const importedFiles = [];
      const importedTempIds = [];

      const onFileImportedCb = (tempFile, importedFile) => {
        importedFiles.push(importedFile);

        // Replace our temp file with the import result
        const tempFileIndex = this.innerFiles.findIndex((f) => this.getItemId(f) === tempFile.id);
        if (tempFileIndex !== -1) {
          this.innerFiles.splice(tempFileIndex, 1, importedFile);
          this.$emit('change', this.innerFiles);
        }
        importedTempIds.push(this.getItemId(tempFile));
        currentImport.nImported++;
      };

      const onProvideAbortCb = (abortImportCb) => {
        currentImport.abortFn = (tempId) => {
          if (tempId && !importedTempIds.includes(tempId)) {
            // If aborting a single file in this import,
            // just decrement this import's total count
            // since onFileImportedCb won't be called
            currentImport.totalToImport--;
          }
          abortImportCb(tempId);
        };
      };

      try {
        await this.importFiles(this.attribute, tempFiles, onFileImportedCb, onProvideAbortCb);
      } catch (err) {
        if (err && err.isAbort) {
          const importedFileIds = importedFiles.map((f) => this.getItemId(f));
          this.innerFiles = this.innerFiles.filter(
            (f) => !importedFileIds.includes(this.getItemId(f))
          );
        }
      }

      // Whether we aborted or not, untrack this import
      const importIdx = this.currentImports.indexOf(currentImport);
      if (importIdx !== -1) {
        this.currentImports.splice(importIdx, 1);
      }

      this.$emit('change', this.innerFiles);
    },
    // EXPOSED
    /**
     * Aborts all the imports in progress. For all
     * these unfinished imports, removes all the files that
     * were uploaded
     */
    abortAllImports() {
      this.currentImports.forEach((i) => {
        if (i.abortFn) {
          i.abortFn();
        }
      });
      this.$emit('change', this.innerFiles);
    },
  },
};
</script>
