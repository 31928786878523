<template>
  <pui-flex
    :class="{ 'pui-error pui-error__root': true, 'pos-fixed': position === 'fixed' }"
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <i v-if="picto" :class="{ 'pui-error__error-icon mdi': true, [picto]: !!picto }"></i>
    <slot></slot>
  </pui-flex>
</template>

<script>
export default {
  name: 'PuiError',
  props: {
    /**
     * How to position the error
     */
    position: {
      type: String,
      default: '',
      validator: (val) => ['', 'fixed'].includes(val),
    },
    /**
     * The name of the error icon
     */
    picto: {
      type: String,
      default: 'mdi-close-circle-outline',
    },
  },
};
</script>
