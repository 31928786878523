<template>
  <pui-dialog
    ref="dialog"
    :showCloseButton="false"
    transition="slide-up"
    class="file-upload-dialog"
    noAutoClose
    @outsideClick="onRequestClose"
  >
    <!-- Upload action buttons -->
    <div v-if="!showConfirmClose" slot="dialog-action" class="dialog-action">
      <pui-button class="upload-button btn-secondary btn-outline" @click="onRequestClose">
        {{ $t('common.upload.dialog.buttons.cancel') }}
      </pui-button>
      <pui-button
        :disabled="!canImport"
        variant="primary"
        class="upload-button"
        @click="$emit('import')"
      >
        {{ $t('common.upload.dialog.buttons.confirm') }}
      </pui-button>
    </div>
    <!-- Confirm close buttons -->
    <div v-if="showConfirmClose" slot="dialog-action" class="dialog-action">
      <pui-button class="confirm-button" outline @click="onCancelClose">
        {{ $t('common.upload.dialog.confirm_close.buttons.cancel') }}
      </pui-button>
      <pui-button variant="primary" class="confirm-button" @click="close">
        {{ $t('common.upload.dialog.confirm_close.buttons.confirm') }}
      </pui-button>
    </div>
    <template #title>
      <i18n path="common.upload.dialog.title.main" class="file-upload-dialog-title">
        <template #primary>
          {{ $t('common.upload.dialog.title.primary') }}
        </template>

        <template #secondary>
          <span class="file-upload-dialog-sub-title">
            <template v-if="showConfirmClose">
              {{ $t('common.upload.dialog.title.secondary.confirm_close') }}
            </template>
            <template v-else>
              {{ $t('common.upload.dialog.title.secondary.choose_media') }}
            </template>
          </span>
        </template>
      </i18n>
    </template>

    <template #default>
      <pui-flex direction="row" class="upload-dialog-content-wrapper">
        <pui-flex
          v-if="showConfirmClose"
          class="upload-dialog-confirm-close-container"
          direction="column"
          flex="1"
        >
          <span class="confirm-close-message">
            {{ $t('common.upload.dialog.confirm_close.message') }}
          </span>
        </pui-flex>
        <pui-flex
          v-else-if="showError"
          class="upload-dialog-error-container"
          justifyContent="center"
          alignItems="center"
          flex="1"
        >
          <i class="mdi mdi-close-circle-outline upload-dialog-error-icon" />
          <pui-flex direction="column" justifyContent="center">
            <div class="upload-dialog-error-message">
              <p>{{ $t('common.upload.dialog.error.message') }}</p>
              <p>
                <a class="pui-link" @click="reloadAfterError">
                  {{ $t('common.upload.dialog.error.reload.click') }}
                </a>
              </p>
            </div>
          </pui-flex>
        </pui-flex>
        <template v-else-if="canShowMainContent">
          <pui-flex
            :flex="showForm ? '60%' : '100%'"
            :class="{ 'drop-zone-container': true, small: showForm }"
          >
            <pui-common-drop-zone-with-preview
              :files="files"
              class="file-upload-dialog-drop-zone"
              @addFile="addFile"
              @removeFile="removeFile"
            />
          </pui-flex>
          <pui-flex
            v-if="showForm"
            flex="40%"
            direction="column"
            class="upload-dialog-content-right"
          >
            <slot name="form"></slot>
          </pui-flex>
        </template>
      </pui-flex>
    </template>
  </pui-dialog>
</template>

<script>
export default {
  name: 'PuiFileUploadDialog',
  props: {
    canImport: {
      type: Boolean,
      required: true,
    },
    showError: {
      type: Boolean,
      required: true,
    },
    reloadAfterError: {
      type: Function,
      required: true,
    },
    /**
     * If the form wrapper and slot should be displayed
     */
    showForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      files: [],
      showConfirmClose: false,
    };
  },
  computed: {
    /**
     * @returns {boolean} if can show the main upload content
     */
    canShowMainContent() {
      return !this.showConfirmClose && !this.showError;
    },
  },
  watch: {
    canShowMainContent(nextValue, prevValue) {
      if (nextValue !== prevValue) {
        this.$emit('canShowMainContent', nextValue);
      }
    },
  },
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    /**
     * Sets the close confirmation
     *
     * @returns {void}
     */
    onRequestClose() {
      if (this.files.length && this.files.length > 0) {
        this.showConfirmClose = true;
      } else {
        this.close();
      }
    },
    /**
     * Removes the close confirmation
     *
     * @returns {void}
     */
    onCancelClose() {
      this.showConfirmClose = false;
    },
    /**
     * Closes the dialog and resets state
     *
     * @returns {void}
     */
    close() {
      this.$refs.dialog.close();
      this.resetState();
      this.$emit('closed');
    },
    /**
     * Initializes the state of the dialog
     * for a new import
     *
     * @returns {void}
     */
    resetState() {
      this.files = [];
      this.showConfirmClose = false;
    },
    // FILES
    /**
     * Adds a file
     *
     * @param {object} file - the file to add
     * @returns {void}
     */
    addFile(file) {
      this.files.push(file);
      this.$emit('changeFiles', this.files);
    },
    /**
     * Removes the given file
     *
     * @param {object} file - the file to remove
     * @param {number} fileIndex - the index of the file to remove
     * @returns {void}
     */
    removeFile(file, fileIndex) {
      if (fileIndex !== -1) {
        this.files.splice(fileIndex, 1);
        this.$emit('changeFiles', this.files);
      }
    },
  },
};
</script>
