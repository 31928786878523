// Filters helper
import services from '../../../core/services';
import { AttributeTypes } from '../../common/constants';

/**
 * Compute filters definition (manage alias, formatters, permissions etc.).
 * @param {Array} filtersDefinition : Filters definition
 */
export const computeFilters = (filtersDefinition) => {
  if (filtersDefinition != null) {
    for (const filterGroup of filtersDefinition) {
      for (const filter of filterGroup.filters) {
        if (filter.options) {
          // Manage filter visibility permission
          let visibility = true;
          if (filter.options.visiblePermission != null) {
            if (
              filter.options.visiblePermission.length > 1 &&
              filter.options.visiblePermission[0] === '!'
            ) {
              // Test inversion (!permission_name)
              visibility = !services
                .getService('auth')
                .hasPermission(filter.options.visiblePermission.substring(1));
            } else {
              visibility = services
                .getService('auth')
                .hasPermission(filter.options.visiblePermission);
            }
          }

          filter.options.visible = visibility;

          // Manage filter enabled permission
          let enabled = true;
          if (filter.options.enabledPermission != null) {
            if (
              filter.options.enabledPermission.length > 1 &&
              filter.options.enabledPermission[0] === '!'
            ) {
              // Test inversion (!permission_name)
              enabled = !services
                .getService('auth')
                .hasPermission(filter.options.enabledPermission.substring(1));
            } else {
              enabled = services.getService('auth').hasPermission(filter.options.enabledPermission);
            }
          }

          filter.options.enabled = enabled;
        }
      }
    }
  }
};

/**
 * Gets filter operator.
 * @param {Object} filter : Filter object
 */
export const getFilterOperator = (filter) => {
  let filterOperator = 'eq';
  if (filter && filter.attribute) {
    if ([AttributeTypes.STRING, AttributeTypes.LABEL].includes(filter.attribute.type)) {
      filterOperator = 'in';
    } else if (
      filter.attribute.type === AttributeTypes.DATE &&
      filter.options &&
      filter.options.range === true
    ) {
      filterOperator = 'between';
    }
  }

  return filterOperator;
};

export default { computeFilters, getFilterOperator };
