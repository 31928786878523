<template>
  <pui-dialog
    ref="dialog"
    :showCloseButton="false"
    transition="slide-up"
    class="external-link-upload-dialog external-link-upload-dialog__root"
    noAutoClose
    @outsideClick="onRequestClose"
  >
    <template #dialog-action>
      <div class="dialog-action">
        <!-- Upload action buttons -->
        <template v-if="!showConfirmClose">
          <pui-button class="upload-button btn-secondary btn-outline" @click="onRequestClose">
            {{ $t('common.attribute_external_link.upload_dialog.buttons.cancel') }}
          </pui-button>
          <pui-button
            :disabled="!canImport"
            variant="primary"
            class="upload-button"
            @click="onImport"
          >
            {{ $t('common.attribute_external_link.upload_dialog.buttons.confirm') }}
          </pui-button>
        </template>

        <!-- Confirm close buttons -->
        <template v-else>
          <pui-button class="confirm-button" outline @click="onCancelClose">
            {{ $t('common.attribute_external_link.upload_dialog.confirm_close.buttons.cancel') }}
          </pui-button>
          <pui-button variant="primary" class="confirm-button" @click="close">
            {{ $t('common.attribute_external_link.upload_dialog.confirm_close.buttons.confirm') }}
          </pui-button>
        </template>
      </div>
    </template>

    <!-- Title -->
    <template #title>
      <i18n
        v-if="!showConfirmClose"
        path="common.attribute_external_link.upload_dialog.title.main"
        class="external-link-upload-dialog-title"
      >
        <template #name>
          {{ attributeLabel }}
        </template>
      </i18n>
      <i18n
        v-else
        path="common.attribute_external_link.upload_dialog.title.confirm_close"
        class="external-link-upload-dialog-title"
      >
      </i18n>
    </template>

    <template #default>
      <pui-flex class="upload-dialog-content-wrapper" direction="column">
        <template v-if="showConfirmClose">
          <span class="confirm-close-message">
            {{ $t('common.attribute_external_link.upload_dialog.confirm_close.message') }}
          </span>
        </template>

        <template v-else>
          <i18n
            v-if="showFilterWarning"
            path="common.attribute_external_link.upload_dialog.message.invalid_files"
            class="invalid-files-message"
            tag="div"
          ></i18n>
          <pui-common-drop-zone-with-preview
            :files="files"
            class="external-link-upload-dialog-drop-zone"
            @addFile="addFile"
            @removeFile="removeFile"
          />
        </template>
      </pui-flex>
    </template>
  </pui-dialog>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'PuiExternalLinkUploadDialog',
  components: {},
  props: {
    attributeLabel: {
      type: String,
      default: '',
    },
    /**
     * Function that filters the selected files and
     * returns those that should be allowed to be uploaded
     */
    filterFiles: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      files: [],
      cbOpts: null,
      showConfirmClose: false,
      showFilterWarning: false,
    };
  },
  computed: {
    /**
     * @returns {boolean} if the user can execute an import
     */
    canImport() {
      return !!this.files.length;
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    /**
     * Opens the dialog with a fresh state
     *
     * @param {File[]} files - array of files to prefill the list with
     * @param {boolean} showFilterWarning - if should show the warning that
     * files were filtered
     * @param {Object} cbOpts - object to pass back through the import event
     */
    open(files = [], showFilterWarning, cbOpts = null) {
      this.$refs.dialog.open();

      this.files = files;
      this.showFilterWarning = showFilterWarning;
      this.cbOpts = cbOpts;
      this.showConfirmClose = false;
    },
    /**
     * Sets the close confirmation, or simply closes the dialog
     */
    onRequestClose() {
      if (this.files.length && this.files.length > 0) {
        this.showConfirmClose = true;
      } else {
        this.close();
      }
    },
    /**
     * Removes the close confirmation
     *
     * @returns {void}
     */
    onCancelClose() {
      this.showConfirmClose = false;
    },
    /**
     * Closes the dialog and resets state
     */
    close() {
      this.$refs.dialog.close();
      this.resetState();
    },
    /**
     * Resets the state of the dialog
     * for a new import
     */
    resetState() {
      this.files = [];
      this.showFilterWarning = false;
      this.cbOpts = null;
      this.showConfirmClose = false;
    },
    // FILES
    /**
     * Adds a file to the upload list
     *
     * @param {object} file - the file to add
     */
    addFile(file) {
      const filtered = this.filterFiles([file]);
      if (filtered.length) {
        this.files.push(filtered[0]);
        // Reset the warning, so that way the user can
        // see it appear for the next invalid file
        this.showFilterWarning = false;
      } else {
        this.showFilterWarning = true;
      }
    },
    /**
     * Removes the given file from the upload list
     *
     * @param {object} file - the file to remove
     * @param {number} fileIndex - the index of the file to remove
     */
    removeFile(file, fileIndex) {
      if (fileIndex !== -1) {
        this.files.splice(fileIndex, 1);
      }
    },
    // IMPORT
    /**
     * Emits the import event and closes the dialog
     */
    onImport() {
      this.$emit('import', [...this.files], this.cbOpts);
      this.close();
    },
  },
};
</script>
