<template>
  <div>
    <!-- Custom attribute -->
    <!-- To display message when attribute are multiple values -->
    <div v-show="displayCustomAttribute" class="pui-attribute-panel">
      <pui-checkbox
        v-if="isBooleanVisible"
        ref="attributeField"
        :showLabel="showLabel"
        :label="piivoTranslate(attribute)"
        :disabled="isDisabled"
        :indeterminate="true"
        :checked="true"
        @change="handleClickCheckBox"
      />
      <!-- ATTRIBUTE STRING AND NOT MULTIPLE -->
      <pui-input
        v-if="displayMultipleValuesInput"
        ref="attributeField"
        :disabled="isDisabled"
        :showLabel="showLabel"
        :label="piivoTranslate(attribute)"
        :value="$t('dam.files.panel_selection.attributes.multiple_attribute_panel.multiple_values')"
        type="text"
        @focus="onFocusMultipleValuesInput"
      />
    </div>
    <div v-show="!displayCustomAttribute">
      <pui-attribute-panel
        ref="attributePanel"
        :allowExtendedDisplayMode="allowExtendedDisplayMode"
        :value="getValue()"
        :attribute="attribute"
        :loadOptionsFunction="loadOptionsFunction"
        :importExternalFiles="importExternalFiles"
        :type="type"
        lazyOptions
        @valueChanged="attributeValueChanged"
        @blur="onBlurAttribute"
        @onCloseList="onCloseList"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import services from '../../../../core/services';
import { attributeValuesAreEqual } from '../../../dam/helpers/formAttributes';
import { AttributeTypes } from '../../constants';
import puiAttributePanel from './AttributePanel';
export default {
  name: 'PuiMultipleAttributePanel',
  components: {
    puiAttributePanel,
  },
  props: {
    allowExtendedDisplayMode: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    attribute: {
      required: true,
    },
    /**
     * Array of files with the original values
     */
    sourceFiles: {
      required: true,
    },
    /**
     * Array of files on which we can edit values
     */
    updatedFiles: {
      required: true,
    },
    /**
     * Force associated label to show/hide (default null)
     */
    displayLabel: {
      type: Boolean,
      default: null,
    },
    /**
     * Load function for paginated Links attribute (async loading) options.
     *
     * @type {(attribute: object, { context?: object }) => Promise<object[]|null>}
     */
    loadOptionsFunction: {
      type: Function,
      default: null,
    },
    /**
     * Import function for external link.
     * Receives an array of objects in which to iterate to upload, returns all the imported files
     *
     * @type {(
     * attribute: Attribute,
     * tempFiles: F[],
     * onFileUploadedCb: (tempFile: F, f: any) => void,
     * provideCancelCb: (cancelCb: (tempId?: string) => void) => void
     * ) => Promise<object[]>}
     **/
    importExternalFiles: {
      type: Function,
      default: null,
    },
    /**
     * Will only trigger a load of the options on the
     * first open of the select. This will only be applied
     * if the value is empty on mount. Otherwise the load will
     * be invoked on mount.
     * Also only applies for simple select links (not AttributeInpputTag)
     */
    lazyOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      AttributeTypes: AttributeTypes,
      valueHasChanged: false,
      wasMultipleValueBeforeChange: false,
    };
  },
  computed: {
    // BOOLEAN
    /**
     * @returns {Boolean} if this attribute is a checkbox input
     */
    isBooleanVisible() {
      return this.attribute.type === AttributeTypes.BOOLEAN;
    },
    /**
     * Display custom attributes when attribute are multiple values and type is not boolean
     */
    displayMultipleValuesInput() {
      return (
        this.isVisible &&
        this.hasMultipleValue &&
        [
          AttributeTypes.STRING,
          AttributeTypes.DATE,
          AttributeTypes.LABEL,
          AttributeTypes.NUMERIC,
          AttributeTypes.LINKS,
        ].includes(this.type)
      );
    },
    /**
     * Display custom attributes when attribute are multiple values
     */
    displayCustomAttribute() {
      return this.isVisible && this.hasMultipleValue;
    },
    /**
     * Return value hasMultipleValue
     * True if attribute has multiple and value and if we need do to display it
     * else false
     */
    hasMultipleValue() {
      return this.attribute._hasMultipleValue;
    },
    /**
     * Show associated label
     * Default true, false if option is defined to false (can be forced by displayLabel prop)
     */
    showLabel() {
      return this.displayLabel != null
        ? this.displayLabel !== false // Forced prop value
        : !this.attribute.options || this.attribute.options.showLabel !== false; // Attribute option value
    },
    isDisabled() {
      return this.disabled || (this.attribute.options && this.attribute.options.enabled === false);
    },
    isVisible() {
      return (
        (!this.attribute.options || this.attribute.options.visible !== false) &&
        (this.attribute.permission == null ||
          services.getService('auth').hasPermission(this.attribute.permission))
      );
    },
    inputValue() {
      return this.value;
    },
    /**
     * Multiple select.
     */
    isMultiSelect() {
      return this.attribute.options && this.attribute.options.multiSelect === true;
    },
    /**
     * Flag indicating if attribute is multiple (for string attribute input or inputTag).
     * */
    multiple() {
      return this.attribute.options && this.attribute.options.multiple === true;
    },
    /**
     * @returns {Boolean} if this attribute is a string input
     */
    isSingleStringVisible() {
      return (
        [AttributeTypes.STRING, AttributeTypes.LABEL].includes(this.attribute.type) &&
        !this.multiple
      );
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    focus() {
      if (this.$refs.attributeField && this.$refs.attributeField.focus) {
        this.$refs.attributeField.focus();
      }
    },
    onKeyUp(event) {
      this.$emit('keyup', event, this.attribute.itemId, this.inputValue);
    },
    /**
     * Load the options of the attribute (for Links attribute).
     *
     * @returns {void}
     */
    async loadOptions() {
      if (this.$refs.attributePanel && this.$refs.attributePanel.loadOptions) {
        this.$refs.attributePanel.loadOptions();
      }
    },
    /**
     * Return compute attribute value
     *
     */
    getValue() {
      // Calculate commonValue if not already exists
      if (!Object.hasOwnProperty.call(this.attribute, '_commonValue')) {
        this.computeMultipleValues(false);
      }

      return this.attribute._commonValue;
    },
    /**
     * Update compute attribute value and emit value changed
     * @params {Object} value - value to set attribute value
     * @returns {void}
     */
    attributeValueChanged(value) {
      this.attribute._commonValue = value;
      this.valueHasChanged = true;
      this.saveValueToFile();
      this.$emit('valueChanged', value);
    },
    /**
     * Calculate compute value for an attribute
     * Compute value is set in _commonValue property
     * @param {boolean} fromSource - whether to compute value from source or updated files
     * @returns {void}
     */
    computeMultipleValues(fromSource) {
      const files = fromSource ? this.sourceFiles : this.updatedFiles;

      for (const currentFile of files) {
        if (!currentFile.attributes || !currentFile.attributes.length) {
          return;
        }

        const attributeFromFile = currentFile.attributes.find((fileAttribute) => {
          return fileAttribute.attributeId === this.attribute.itemId;
        });

        if (!attributeFromFile) {
          return;
        }

        if (
          attributeFromFile &&
          [AttributeTypes.LINKS].includes(this.type) &&
          !this.attribute._dataLoaded &&
          !!attributeFromFile.value
        ) {
          this.loadOptions();
          this.attribute._dataLoaded = true;
        }

        if (Object.hasOwnProperty.call(this.attribute, '_commonValue')) {
          if (
            !attributeValuesAreEqual(
              this.attribute._commonValue,
              attributeFromFile.value,
              this.type
            ) &&
            files.length > 1
          ) {
            this.$set(this.attribute, '_hasMultipleValue', true);
            switch (this.type) {
              case AttributeTypes.LINKS:
                this.attribute._commonValue = [];
                break;
              default:
                this.attribute._commonValue = null;
                break;
            }
            return;
          }
        } else {
          this.$set(this.attribute, '_commonValue', attributeFromFile.value);
        }
      }
    },
    /**
     * When custom attribute are display
     * On click check box set value to true
     * Focus new attribute displayed
     * Emit valueChanged
     */
    handleClickCheckBox() {
      this.attribute._hasMultipleValue = false;
      this.wasMultipleValueBeforeChange = true;
      this.$emit('valueChanged', true);
      this.attribute._commonValue = true;
      if (this.$refs.attributePanel) {
        this.$refs.attributePanel.focus();
      }
    },
    /**
     * When custom attribute are display, on focus input
     * Focus new attribute displayed
     * Emit valueChanged
     */
    onFocusMultipleValuesInput() {
      this.attribute._hasMultipleValue = false;
      this.wasMultipleValueBeforeChange = true;
      this.$nextTick(() => {
        if (this.$refs.attributePanel) {
          this.$refs.attributePanel.focus();
        }
      });
    },
    /**
     * On blur attribute, if value has not changed, display custom attributes
     */
    onBlurAttribute() {
      if (this.wasMultipleValueBeforeChange && !this.valueHasChanged) {
        this.attribute._hasMultipleValue = true;
      }
    },
    /**
     * On close attribute list, if value has not changed, display custom attributes
     */
    onCloseList() {
      if (this.wasMultipleValueBeforeChange && !this.valueHasChanged) {
        this.attribute._hasMultipleValue = true;
      }
    },
    /**
     * Saves the curernt attribute value to the file(s)' attribute's value(s)
     *
     * @returns {void}
     */
    saveValueToFile() {
      if (!Object.hasOwnProperty.call(this.attribute, '_commonValue')) {
        // Nothing to save
        return;
      }

      for (const currentFile of this.updatedFiles) {
        if (!currentFile.attributes || !currentFile.attributes.length) {
          return;
        }

        const attributeFromFile = currentFile.attributes.find((fileAttribute) => {
          return fileAttribute.attributeId === this.attribute.itemId;
        });

        if (attributeFromFile) {
          this.$set(attributeFromFile, 'value', this.attribute._commonValue);
        }
      }
    },
    // EXPOSED
    /**
     * Function call by parent to reset compute value
     * @param {boolean} fromSource - whether to compute value from source or updated files
     */
    resetComputeValues(fromSource) {
      delete this.attribute._commonValue;
      this.computeMultipleValues(fromSource);
    },
  },
  /**
   * Mounted hook.
   */
  mounted() {
    this.computeMultipleValues(false);
  },
};
</script>

<style scoped></style>
