<template>
  <pui-message-box
    v-bind="$attrs"
    class="common-message-box common-message-box__root"
    v-on="$listeners"
  >
    <template v-if="queuedCount > 0" #left>
      <div class="common-message-box__queue-count">
        {{ queuedCount }}
      </div>
    </template>
  </pui-message-box>
</template>

<script>
export default {
  name: 'PuiCommonMessageBox',
  props: {
    queuedCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>
