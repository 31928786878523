<template>
  <div
    class="pui-ball-loader pui-ball-loader__root"
    :style="{
      '--loader-width': loaderWidth,
      '--loader-color': loaderColor,
    }"
  >
    <div class="pui-ball-loader__ball ball1"></div>
    <div class="pui-ball-loader__ball ball2"></div>
    <div class="pui-ball-loader__ball ball3"></div>
  </div>
</template>

<script>
export default {
  name: 'PuiBallLoader',
  props: {
    /**
     * Css length value for the width of the loader
     */
    loaderWidth: {
      type: String,
      default: '100px',
    },
    /**
     * Css value for the color of the loader
     */
    loaderColor: {
      type: String,
      default: 'var(--color-primary)',
    },
  },
};
</script>
