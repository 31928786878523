import Vue from 'vue';

import { Response } from '../../../core/types/http';
import { ActionDetailsBase } from './actions';

const UPLOAD_API_URL = 'api/piivo/v1/uploads';

export interface UploadDetails extends ActionDetailsBase {
  itemId: string;
  sessionId: string;
  taskId: string;
  taskName: string;
  uploadPath: string;
  /**
   * The ids of the uploaded files
   */
  uploadedFileIds: string[];
  uploader: string;
  createdDate: string;
  modifiedDate: string;
}

export default {
  /**
   * Gets the status of an upload
   *
   * @param uploadId - the id of the upload to check
   * @returns the request result
   */
  getUploadById(uploadId: string): Response<UploadDetails> {
    return Vue.http.get(`${UPLOAD_API_URL}/${uploadId}`) as Response<UploadDetails>;
  },

  /**
   * Creates an upload
   *
   * @returns the request result
   */
  createUpload(): Response<string> {
    return Vue.http.post(`${UPLOAD_API_URL}`) as Response<string>;
  },

  /**
   * Sends a file to an upload
   *
   * @param uploadId - the upload to send the files to
   * @param file - the file to upload
   * @returns the request result
   */
  sendFileToUpload(uploadId: string, file: File): Response<unknown> {
    const formData = new FormData();
    formData.append('myFile', file);

    return Vue.http.post(`${UPLOAD_API_URL}/${uploadId}/files`, formData) as Response<unknown>;
  },

  /**
   * Sends a file to an upload
   *
   * @param uploadId - the upload to send the files to
   * @param values - values for the upload task
   * @param taskName - the task to execute the upload
   * @returns the request result
   */
  executeUpload(
    uploadId: string,
    values: { [x: string]: any },
    taskName: string | null
  ): Response<{ taskId: string; uploadId: string }> {
    return Vue.http.post(
      `${UPLOAD_API_URL}/${uploadId}/run/${taskName ?? ''}`,
      values
    ) as Response<{ taskId: string; uploadId: string }>;
  },
};
