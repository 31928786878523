import store from '../../../core/store';
import {
  CLEAR_ALL_CATEGORIES,
  NAMESPACE as USER_SETTINGS_NAMESPACE,
  REMOVE_CATEGORY,
  SET_CATEGORY,
} from '../store/userSettings';
import { IUserSettingsService } from './types';

export class UserSettingsService implements IUserSettingsService {
  /**
   * Gets category state for the given name
   *
   * @param categoryName - name of the category to retrieve
   */
  getCategory(categoryName: string): unknown {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${USER_SETTINGS_NAMESPACE}/getCategory`](categoryName);
  }

  /**
   * Saves category state under the given name
   *
   * @param categoryName - name to save the category under
   * @param categoryState - state to save
   */
  setCategory(categoryName: string, categoryState: Record<any, any>): void {
    store.commit(`${USER_SETTINGS_NAMESPACE}/${SET_CATEGORY}`, { categoryName, categoryState });
  }

  /**
   * Gets category state for the given name
   *
   * @param categoryName - name of the category to retrieve
   * @param key - name of the key to retrieve
   */
  getInCategory(categoryName: string, key: string): unknown {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${USER_SETTINGS_NAMESPACE}/getInCategory`](categoryName, key);
  }

  /**
   * Saves a value under the given key, for the given category
   *
   * @param categoryName - the name of the category
   * @param key - the key in the category to save under
   * @param value - the value to save
   */
  setInCategory(categoryName: string, key: string, value: Record<any, any>): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    void store.dispatch(`${USER_SETTINGS_NAMESPACE}/setInCategory`, { categoryName, key, value });
  }

  /**
   * Removes a category
   *
   * @param categoryName - the name of the category to remove
   */
  removeCategory(categoryName: string): void {
    store.commit(`${USER_SETTINGS_NAMESPACE}/${REMOVE_CATEGORY}`, { categoryName });
  }

  /**
   * Clears all categories
   */
  clearAllCategories(): void {
    store.commit(`${USER_SETTINGS_NAMESPACE}/${CLEAR_ALL_CATEGORIES}`);
  }
}
