<template>
  <div
    :draggable="!isAddButton && !disabled"
    class="pui-external-link-item pui-external-link-item__root"
    @dragstart="onDragStart"
    @dragend="onDragEnd"
  >
    <template v-if="!isAddButton">
      <div class="pui-external-link-item__item-wrapper">
        <div
          :class="{
            'pui-external-link-item__target-left': true,
            isBeingDraggedOver: isBeingDraggedOverBefore,
            showDragTarget: isBeforeTargetActive,
          }"
          @dragenter.stop="onDragEnter('Before', $event)"
          @dragover.stop="onDragOver('Before', $event)"
          @dragleave.stop="onDragLeave('Before', $event)"
          @drop.stop.prevent="onDrop('Before', $event)"
        ></div>
        <pui-aspect-ratio ratio="1:1" class="pui-external-link-item__aspect-ratio">
          <div
            class="pui-external-link-item__target-inside pui-external-link-item__target-inside-left"
            @dragenter.stop="onDragEnter('Before', $event)"
            @dragover.stop="onDragOver('Before', $event)"
            @dragleave.stop="onDragLeave('Before', $event)"
            @drop.stop.prevent="onDrop('Before', $event)"
          ></div>
          <div
            class="pui-external-link-item__target-inside pui-external-link-item__target-inside-right"
            @dragenter.stop="onDragEnter('After', $event)"
            @dragover.stop="onDragOver('After', $event)"
            @dragleave.stop="onDragLeave('After', $event)"
            @drop.stop.prevent="onDrop('After', $event)"
          >
            <pui-button
              v-if="!isItemBeingDragged"
              class="pui-external-link-item__remove-button"
              picto="mdi-close"
              @click.stop="$emit('removeItem')"
            ></pui-button>
          </div>
          <pui-card
            :class="{
              'pui-external-link-item__card': true,
              tempFile: item._isTempFile,
              isBeingDragged,
            }"
          >
            <template #default>
              <template v-if="item._isTempFile">
                <svg
                  class="temp-file-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    class="temp-file-icon-path"
                    d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z"
                  />
                </svg>
              </template>
              <template v-else>
                <pui-file-thumbnail-viewer
                  :src="item.thumbnail ? item.thumbnail.url : null"
                  objectFit="contain"
                  class="pui-external-link-item__viewer"
                >
                </pui-file-thumbnail-viewer>
              </template>
            </template>
          </pui-card>
        </pui-aspect-ratio>
        <pui-flex
          v-if="extendedMode"
          :title="item.name"
          :style="metaStyle"
          class="pui-external-link-item__meta"
          alignItems="center"
          justifyContent="center"
        >
          <span :title="item.name">{{ item.name }}</span>
        </pui-flex>

        <div
          :class="{
            'pui-external-link-item__target-right': true,
            isBeingDraggedOver: isBeingDraggedOverAfter,
            showDragTarget: isAfterTargetActive,
          }"
          @dragenter.stop="onDragEnter('After', $event)"
          @dragover.stop="onDragOver('After', $event)"
          @dragleave.stop="onDragLeave('After', $event)"
          @drop.stop.prevent="onDrop('After', $event)"
        ></div>
      </div>
    </template>

    <template v-else>
      <pui-aspect-ratio ratio="1:1" class="pui-external-link-item__aspect-ratio">
        <svg
          :class="{ 'pui-external-link-item__upload-btn': true, error, disabled }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 55 58"
          @click="openUploadDialog"
        >
          <g transform="translate(-11 -11)">
            <g transform="translate(1 -4)">
              <g
                transform="translate(10 15)"
                class="background-fill"
                stroke="#bebebe"
                stroke-width="1"
                stroke-dasharray="1 3"
              >
                <rect width="25" height="25" rx="3" stroke="none" />
                <rect x="0.5" y="0.5" width="24" height="24" rx="2.5" fill="none" />
              </g>
              <g
                transform="translate(40 15)"
                class="background-fill"
                stroke="#bebebe"
                stroke-width="1"
                stroke-dasharray="1 3"
              >
                <rect width="25" height="25" rx="3" stroke="none" />
                <rect x="0.5" y="0.5" width="24" height="24" rx="2.5" fill="none" />
              </g>
              <g
                class="colored-stroke background-fill"
                transform="translate(40 45)"
                stroke-width="1"
                stroke-dasharray="1 3"
              >
                <rect width="25" height="25" rx="3" stroke="none" />
                <rect x="0.5" y="0.5" width="24" height="24" rx="2.5" fill="none" />
              </g>
              <g
                transform="translate(10 45)"
                class="background-fill"
                stroke="#bebebe"
                stroke-width="1"
                stroke-dasharray="1 3"
              >
                <rect width="25" height="25" rx="3" stroke="none" />
                <rect x="0.5" y="0.5" width="24" height="24" rx="2.5" fill="none" />
              </g>
            </g>
            <path
              class="colored-fill"
              d="M10.639,17.583l3.472,4.167,4.861-6.25,6.25,8.333H5.778M28,25.222V5.778A2.777,2.777,0,0,0,25.222,3H5.778A2.778,2.778,0,0,0,3,5.778V25.222A2.778,2.778,0,0,0,5.778,28H25.222A2.778,2.778,0,0,0,28,25.222Z"
              transform="translate(19 19)"
            />
            <text
              data-name="+"
              transform="translate(47 63)"
              fill="#565f62"
              font-size="22"
              font-family="OpenSans-Regular, Open Sans"
            >
              <tspan x="0" y="0">+</tspan>
            </text>
          </g>
        </svg>
      </pui-aspect-ratio>
    </template>
  </div>
</template>

<script>
import { isFileDrag } from 'piivo-ui/src/utils/file';

import { ATTRIBUTE_EXTERNAL_LINK_ITEM_META_HEIGHT_PX } from '../../constants';
import { FILE_DROP_EFFECT, ITEM_DROP_EFFECT } from '../../constants/attributes';

export default {
  name: 'PuiExternalLinkItem',
  props: {
    /**
     * The external link item
     */
    item: {
      type: Object,
      required: true,
    },
    /**
     * Gets the id of an item
     */
    getItemId: {
      type: Function,
      required: true,
    },
    /**
     * Shows extra details of the file
     */
    extendedMode: {
      type: Boolean,
      default: false,
    },
    /**
     * Styles as error
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * If an item in the list is being dragged
     */
    isItemBeingDragged: {
      type: Boolean,
      default: null,
    },
    /**
     * Prevents adding more files
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Display as an add button instead a file
     */
    isAddButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Style the "before" target as being dragged over
     */
    isBeforeTargetActive: {
      type: Boolean,
      default: false,
    },
    /**
     * Style the "after" target as being dragged over
     */
    isAfterTargetActive: {
      type: Boolean,
      default: false,
    },
    /**
     * If the "before" target should be disabled
     */
    disableBeforeTarget: {
      type: Boolean,
      default: false,
    },
    /**
     * If the "after" target should be disabled
     */
    disableAfterTarget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBeingDragged: false,
      isBeingDraggedOverBefore: false,
      isBeingDraggedOverAfter: false,
    };
  },
  computed: {
    /**
     * @returns {object} style object for the item meta
     */
    metaStyle() {
      return {
        height: `${ATTRIBUTE_EXTERNAL_LINK_ITEM_META_HEIGHT_PX}px`,
      };
    },
    /**
     * @returns {boolean} if should style as a drop target
     */
    isDropTarget() {
      return this.isItemBeingDragged && !this.isBeingDragged;
    },
  },
  watch: {
    /**
     * Emits the value of 'isBeingDraggedOverBefore' on change
     */
    isBeingDraggedOverBefore(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('changeDragOverBefore', newVal);
      }
    },
    /**
     * Emits the value of 'isBeingDraggedOverAfter' on change
     */
    isBeingDraggedOverAfter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('changeDragOverAfter', newVal);
      }
    },
  },
  methods: {
    /**
     * Emits the 'openUploadDialog' event
     */
    openUploadDialog() {
      if (this.disabled) {
        return;
      }

      this.$emit('openUploadDialog');
    },
    /**
     * Handles the start of being dragged
     *
     * @param {DragEvent} e - the drag event
     */
    onDragStart(e) {
      if (this.disabled) {
        return;
      }
      this.isBeingDragged = true;

      e.dataTransfer.effectAllowed = ITEM_DROP_EFFECT;
      // Setting data allows dragging/dropping
      e.dataTransfer.setData('text/html', null); // Cannot be empty string

      this.$emit('dndStart');
    },
    /**
     * Emits 'dndEnd'. Removes dragging styles
     *
     * @param {DragEvent} e - the drag event
     */
    onDragEnd(e) {
      this.isBeingDragged = false;
      this.$emit('dndEnd', e);
    },
    /**
     * @param {DragEvent} e - the drag event
     * @returns {Boolean} if the event corresponds to an item move
     * or a file drag
     */
    canApplyDragoverStyles(e) {
      // Only apply styles if are a target for a move,
      // or if we are dragging files
      return this.isDropTarget || isFileDrag(e);
    },
    /**
     * Handles a drag entering us
     * Applies drag over styles if necessary
     *
     * @param {string} target - the name of the target
     * @param {DragEvent} e - the drag event
     */
    onDragEnter(target, e) {
      this[`isBeingDraggedOver${target}`] =
        !this[`disable${target}Target`] && this.canApplyDragoverStyles(e);
    },
    /**
     * Handles a drag over us.
     * Applies drag over styles and enables a drop if necessary
     *
     * @param {string} target - the name of the target
     * @param {DragEvent} e - the drag event
     */
    onDragOver(target, e) {
      if (this[`disable${target}Target`] || !this.canApplyDragoverStyles(e)) {
        return;
      }

      e.preventDefault(); // Allows drop
      this[`isBeingDraggedOver${target}`] = true;
      // We must add an effect for the drop to receive files
      e.dataTransfer.dropEffect = isFileDrag(e) ? FILE_DROP_EFFECT : ITEM_DROP_EFFECT;
      return false; // Prevent default action of browser. For compatibility
    },
    /**
     * Removes drag over styles
     *
     * @param {string} target - the name of the target
     */
    onDragLeave(target) {
      this[`isBeingDraggedOver${target}`] = false;
    },
    /**
     * Emits 'dndDrop' or 'dndMove'. Removes drag over styles
     *
     * @param {string} target - the name of the target
     * @param {DragEvent} e - the drag event
     */
    onDrop(target, e) {
      this[`isBeingDraggedOver${target}`] = false;
      if (isFileDrag(e)) {
        this.$emit(`dndDrop${target}`, e);
      } else {
        this.$emit(`dndMove${target}`, e);
      }
    },
  },
};
</script>
