import Vue from 'vue';

import { ResolvedResponse, Response } from '../../../core/types/http';

const BULK_ACTIONS_API = '/api/piivo/v1/bulkactions';

export enum ActionStatus {
  /**
   * Action created on server
   */
  NEW = 'New',
  /**
   * Action queued for execution on server
   */
  ENQUEUED = 'Enqueued',
  /**
   * Action execution started
   */
  STARTED = 'Started',
  /**
   * Action execution finished with success
   */
  FINISHED = 'Finished',
  /**
   * Action execution finished with failure
   */
  INERROR = 'InError',
}

export interface ActionDetailsBase {
  status: ActionStatus;
}

export interface ActionDetails<Result = object> extends ActionDetailsBase {
  actionId: string;
  sessionId: string;
  taskId: string;
  taskName: string;
  result: Result;
  progress: number;
}

export default {
  /**
   * Get status for a action
   *
   * @param actionId - the id the of the action
   */
  async getAction(actionId: string | number): Response<ActionDetailsBase> {
    return Vue.http.get(`${BULK_ACTIONS_API}/${actionId}`) as Response<ActionDetailsBase>;
  },

  /**
   * Creates an action
   * @returns the action id
   */
  async createAction<TResult = unknown>(): Promise<ActionDetails<TResult>> {
    const response = (await Vue.http.post(BULK_ACTIONS_API)) as ResolvedResponse<
      ActionDetails<TResult>
    >;

    return response.body;
  },

  /**
   * Runs an action
   *
   * @param actionId - the  action id
   * @param taskName  - the task name to run
   * @param parameters - action parameters
   * @returns the action details
   */
  async runAction<TResult = unknown>(
    actionId: string | number,
    taskName: string,
    parameters: Record<string, object>
  ): Promise<ActionDetails<TResult>> {
    const response = (await Vue.http.post(
      `${BULK_ACTIONS_API}/${actionId}/run/${taskName}`,
      parameters
    )) as ResolvedResponse<ActionDetails<TResult>>;

    return response.body;
  },
};
