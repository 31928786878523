import Vue from 'vue';

import { PersistKeys } from '../../../core/store/plugins/persistence';

export const NAMESPACE = 'userSettings';

export const SET_CATEGORY = 'SET_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const CLEAR_ALL_CATEGORIES = 'CLEAR_ALL_CATEGORIES';

const SET_IN_CATEGORY_MUTATION = 'SET_IN_CATEGORY_MUTATION';

const CATEGORY_STATE_PREFIX = 'setting_';

const getCategoryStateKey = (categoryName) => `${CATEGORY_STATE_PREFIX}${categoryName}`;

/**
 * Category data store module.
 */
export const UserSettingsModule = {
  namespaced: true,
  state: {
    [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
  },

  getters: {
    getCategory: (state) => (categoryName) => state[getCategoryStateKey(categoryName)],
    getInCategory: (state, getters) => (categoryName, key) => {
      const categoryState = getters.getCategory(categoryName);
      if (categoryState) {
        return categoryState[key];
      }
      return undefined;
    },
  },

  actions: {
    /**
     * Sets a value in a category
     *
     * @param {object} context - store context
     * @param {Function} context.commit - store commit function
     * @param {object} context.state - store state
     * @param {object} payload - action payload
     * @param {string} payload.categoryName - the key for the category
     * @param {string} payload.key - the key to save
     * @param {*} payload.value - the value to save
     * @returns {void}
     */
    setInCategory({ commit, state }, { categoryName, key, value }) {
      if (Object.hasOwnProperty.call(state, getCategoryStateKey(categoryName))) {
        commit(SET_IN_CATEGORY_MUTATION, { categoryName, key, value });
      } else {
        commit(SET_CATEGORY, { categoryName, categoryState: { [key]: value } });
      }
    },
  },

  mutations: {
    /**
     * Sets the category as a property of
     * the state under the categoryName key
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.categoryName - the key for the category
     * @param {object} payload.categoryState - the state to save
     */
    [SET_CATEGORY](state, { categoryName, categoryState }) {
      // We need to use use Vue.set because we are adding
      // a property to the root state, and root property additions
      // are not reactive
      Vue.set(state, getCategoryStateKey(categoryName), categoryState);
    },
    /**
     * Sets the category as a property of
     * the state under the categoryName key
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.categoryName - the key for the category
     * @param {object} payload.key - the key to save
     * @param {object} payload.value - the state to save
     */
    [SET_IN_CATEGORY_MUTATION](state, { categoryName, key, value }) {
      // We need to use use Vue.set because we are adding
      // a property to the root state, and root property additions
      // are not reactive
      Vue.set(state[getCategoryStateKey(categoryName)], key, value);
    },
    /**
     * Removes a category
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.categoryName - the key for the category
     */
    [REMOVE_CATEGORY](state, { categoryName }) {
      Vue.delete(state, getCategoryStateKey(categoryName));
    },
    /**
     * Clears all categories
     *
     * @param {object} state - Store State
     */
    [CLEAR_ALL_CATEGORIES](state) {
      Object.keys(state).forEach((key) => {
        if (key.startsWith(CATEGORY_STATE_PREFIX)) {
          Vue.delete(state, getCategoryStateKey(key));
        }
      });
    },
  },
};
